import React, { useState, useContext } from 'react';
import email_icon from '../Assets/email_icon.png';
import user_icon from '../Assets/user_icon.png';
import './LoginSignup.css'; 
import { Helmet } from 'react-helmet';
import axiosInstance from '../../../axiosInstance'; 
import { UserContext } from '../../../UserContext'; 
import Validation from './LoginSignupValidation';
import { Link, useNavigate } from 'react-router-dom';
import UserEmail from '../UserEmail'; 
import Mount from '../Mount';
import AlertBanner from '../Banner/AlertBanner';

const API_URL = process.env.REACT_APP_API_URL;


const LoginSignup = () => { 
  const {
    username, setUsername,
    email, setEmail,
    isStudent, 
    setIsStudent
  } = useContext(UserContext);
  const [action, setAction] = useState("Sign Up");
 
  const [errors, setErrors] = useState({});
  const [isPasted, setIsPasted] = useState(false);
  const [loading, setLoading] = useState(false); 

  const [successMessage, setSuccessMessage] = useState(''); 
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const navigate = useNavigate();

  const handleUsernameChange = (e) => {
    setUsername(e.target.value)
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value.toLowerCase());
  };


  const handleEmailPaste = (e) => {
    e.preventDefault(); 
    setIsPasted(true);
    alert('Please type your primary email address.');
  };

  const handleLoginClick = async () => {
    if (loading) return; 
    setLoading(true);
    setShowAlert(false); 
    setSuccessMessage(''); 

    if (action === "Login" && email) {
        const values = { 
          email: `${email}`
        };
            try {
                const res = await axiosInstance.post(`${API_URL}/users/enter`, values, { withCredentials: true });
                if (res.data.ok) {
                    
                    alert('Magic link sent to your email.');
                   
                } else {
                    alert(res.data.message);
                }
            } catch (err) {
                console.error('Error logging in:', err);
                setShowAlert(true); 
                if (err.response && err.response.data && err.response.data.error) {
                    alert(err.response.data.error);
                } else {
                    alert('An unexpected error occurred');
                }
            }
        
    }

    setLoading(false);
    setAction("Login");
  };

  const handleSignupClick = async () => {
    if (loading) return;
    setLoading(true);
    setShowAlert(false);
    setSuccessMessage("");
  
    if (action === "Sign Up") {
      // Check for empty fields first
      if (!username || !email) {
        setErrors({
          username: !username ? "Username is required" : "",
          email: !email ? "Email is required" : "",
        });
        setLoading(false);
        return; // Stop execution here if fields are empty
      }
  
      const values = { 
        username, 
        email
        
      };
      const validationErrors = Validation(values);
      setErrors(validationErrors);
  
      // Check if there are validation errors
      const hasValidationErrors = Object.values(validationErrors).some(error => error !== "");
  
      if (hasValidationErrors) {
        setErrors(validationErrors); // Set errors only if there are validation errors
        setLoading(false);
        return; // Stop execution if there are validation errors
      }
  
      // Proceed only if there are no errors
      navigate('/account-type');
    }
  
    setLoading(false);
    setAction("Sign Up");
  };
  
  const handleFormKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <Mount>
      <Helmet>
        <title>{action === "Login" ? "Login" : "Sign Up"} - Contranorm Media & Production</title>
      </Helmet>
      <div className="page">
        <div className="header_text">
            <div className="text">{action}</div>
        </div>
        <div className="underline"></div>
          
          {successMessage && (
            <div className="success-message">
              {successMessage}
            </div>
          )}
          <form action="" onClick={handleFormKeyPress}>
            <div className="inputs">
              {action === "Login" ? (
                <div></div>
                ) : (
                  <div className="input">
                    <img src={user_icon} id="user_icon" alt="" />
                    <input 
                      type="text" 
                      placeholder="Full Legal Name" 
                      value={username} 
                      onChange={handleUsernameChange} 
                    />
                    {errors.username && <span className='text-danger'>{errors.username}</span>}
                  </div>
              )}
              <div className="input">
                <img src={email_icon} id="email_icon" alt="" />
                <UserEmail
                  userEmail={email}
                  enterEmail={handleEmailChange}
                  handleEmailPaste={handleEmailPaste}
                />
                {errors.email && <span className='text-danger'>{errors.email}</span>}
              </div>
          </div>
          </form>
          {action === "Sign Up" ? (
            <div></div>
           ) : (
            <div className="wrap-text">
              <Link to="/policies"><span>Terms & Conditions</span></Link>
            </div>
          )}
          {action === "Login" ? (
            <div></div>
            ) : (
            <div className="wrap-text">
              <Link to="/privacy"><span>Privacy Policy</span></Link>
            </div>
          )}
          <div className="submit-container">
            <div className={action === "Login" ? "submit gray" : "submit"} onClick={handleSignupClick}>Sign Up</div>
            <div className={action === "Sign Up" ? "submit gray" : "submit"} onClick={handleLoginClick}>Login</div>
          </div>
      </div>
      <AlertBanner 
        showAlert={showAlert} 
        showSaveButton={false} 
        handleCancel={() => setShowAlert(false)}>
        <div style={{ display: 'flex',  width: '100%' }}>
          <span style={{ flex: 1 }}>It appears that you may be in a geo-restricted area or this feature has been temporarily disabled by your device.</span>
        
          
        </div>
      </AlertBanner>
    </Mount>
  );
}

export default LoginSignup;
