import React, { useState, useEffect, useMemo } from 'react';
import './AdminDashboard.css';
import axios from 'axios';
import Card from './Card';
import { FaFeather, FaBrush, FaStar, FaQuestionCircle } from 'react-icons/fa'; 

const API_URL = process.env.REACT_APP_API_URL;

function AdminDashboard() {
  const [cards, setCards] = useState([]);
  const [news, setNews] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('Month'); 
  const cardOrder = useMemo(() => ['Clients', 'Statistics', 'Expenses', 'Revenue'], []);
  const iconMap = {
    'FaFeather': <FaFeather />, 
    'FaBrush': <FaBrush />,
    'FaStar': <FaStar />,
    'FaQuestionCircle': <FaQuestionCircle />,  
  };

  const fetchData = async (filter) => {
    
    try {
      const response = await axios.get(`${API_URL}/api/data`);
      const data = response.data;

      const statisticsResponse = await axios.get(`${API_URL}/api/stars/statistics?filter=${filter}`);
      const statisticsData = statisticsResponse.data;

      const statisticsCard = {
        _id: 'statistics-card',
        name: 'Statistics',
        icon: 'bi bi-star',
        items: statisticsData,
        filter: filter,
      };

      const combinedCards = [...data.cards, statisticsCard];
      const sortedCards = combinedCards.sort((a, b) => {
        return cardOrder.indexOf(a.name) - cardOrder.indexOf(b.name);
      });

      setCards(sortedCards);
      setNews(data.news);
    } catch (error) {
      console.error('Error fetching API data', error);
    }
  };
 

  useEffect(() => {
    fetchData(selectedFilter); 
  }, [selectedFilter, cardOrder]);

  const getCardClass = useMemo(() => {
    return (amount, name) => {
      if (name === 'Revenue') {
        if (amount <= 600) return 'card-danger';
        if (amount >= 601 && amount <= 999) return 'card-white';
        return 'card-white';
      }
      return 'card-white';
    };
  }, []);

  const getAmountClass = useMemo(() => {
    return (amount, name) => {
      if (name === 'Revenue') {
        if (amount <= 600) return 'text-danger';
        if (amount > 1000) return 'text-success';
        return 'text-warning';
      }
      if (name === 'Clients') {
        return amount < 10 ? 'text-danger' : 'text-success';
      }
      if (name === 'Statistics') {
        return amount <= 100 ? 'text-danger' : 'text-success';
      }
      if (name === 'Expenses') {
        return amount <= 100 ? 'text-success' : 'text-danger';
      }
      return '';
    };
  }, []);

  return (
    <section className="dashboard section">
      <div className="dashboard-container">
        <div className="row small-cards-row">
          {cards.map((card) => (
            <Card
              key={card._id}
              card={{ ...card, amount: Number.isNaN(parseInt(card.amount, 10)) ? 0 : parseInt(card.amount, 10) }}
              className={getCardClass(card.amount, card.name)}
              amountClass={getAmountClass(card.amount, card.name)}
              filterChange={(filter) => {
                
                setSelectedFilter(filter); // Update the selected filter state
              }}
            />
          ))}
        </div>
        <div className="row large-card-row">
          <Card key="news" card={{ name: "" }} className="large-card">
            {news.map((item, index) => (
              <React.Fragment key={item._id}>
                <div className="news-item">
                  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    {/* Use iconMap here to render the icon directly */}
                    <div className="news-icon">
                      {iconMap[item.img] || <FaQuestionCircle />} {/* Fallback to FaQuestionCircle if the icon is not found */}
                    </div>
                    <div className="news-content">
                      <span className="news-category">{item.category}</span>
                    </div>
                  </a>
                </div>
                {index < news.length - 1 && <hr className="large-card-row-divider" />}
              </React.Fragment>
            ))}
          </Card>
        </div>
      </div>
    </section>
  );
}

export default AdminDashboard;
