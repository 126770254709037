import React, { createContext, useState, useEffect } from 'react';
import axiosInstance from './axiosInstance';
import { useNavigate } from 'react-router-dom';

export const UserContext = createContext();

const API_URL = process.env.REACT_APP_API_URL;
console.log('API_URL:', API_URL);

const UserProvider = ({ children }) => {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('')
  const [user, setUser] = useState(null);
  const [googleDriveID, setGoogleDriveID] = useState(''); 
  const [googleDriveLink, setGoogleDriveLink] = useState(null); 
  const [isStudent, setIsStudent] = useState(false);
  const navigate = useNavigate();

  const handleMagicLinkLogin = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    const magicLink = urlParams.get('magicLink');

    if (email && magicLink) {
      try {
        const res = await axiosInstance.post(`${API_URL}/users/enter`, { email, magicLink }, { withCredentials: true });
        console.log('Response from server:', res.data);
        if (res.data.success) {
          localStorage.setItem('token', res.data.token);
          setUser({ email, role: res.data.role });

          sessionStorage.setItem('userEmail', email);
          sessionStorage.setItem('userRole', res.data.role);
          setUser({ email, role: res.data.role });

          navigateBasedOnRole(res.data.role);
        } else {
          console.error('Authentication failed:', res.data.message);
        }
      } catch (error) {
        console.error('Error during sign-in:', error);
      }
    } else {
      
      const savedEmail = sessionStorage.getItem('userEmail');
      const savedRole = sessionStorage.getItem('userRole');

      if (savedEmail) {
        setUser({ email: savedEmail, role: savedRole });
        navigateBasedOnRole(savedRole);
      } else {
        console.error('Magic link or email not found in URL.');
      }
    } 
  };

  const navigateBasedOnRole = (role) => {
    if (role === 'admin') {
      navigate('/admin');
    } else if (role === 'student') {
      navigate('/sat-dashboard');  
    } else {
      navigate('/dashboard');
    }
  };
  

  useEffect(() => {
    const token = localStorage.getItem('token');
    
    if (token) {
      const savedEmail = sessionStorage.getItem('userEmail');
      const savedRole = sessionStorage.getItem('userRole');
      
      if (savedEmail && savedRole) {
        setUser({ email: savedEmail, role: savedRole });
        navigateBasedOnRole(savedRole);
      }
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      handleMagicLinkLogin(urlParams);
    }
  }, []);
  

  const signIn = async (email, magicLink,isStudent) => {
    console.log(`Sending login request for ${email} with magic link ${magicLink}`);
    try {
      const res = await axiosInstance.post(`${API_URL}/users/enter`, { email, magicLink }, { withCredentials: true });
      console.log('Response from server:', res.data);
      if (res.data.success) {
        localStorage.setItem('token', res.data.token);
        const user = { email, role: res.data.role };
        setUser(user);
        navigateBasedOnRole(res.data.role);
        return user;
      } else {
        console.error('Authentication failed:', res.data.message);
      }
    } catch (error) {
      console.error('Error during sign-in:', error);
    }
  };

  return (
    <UserContext.Provider value={{ 
      user, setUser, 
      signIn, 
      googleDriveLink, 
      setGoogleDriveLink, 
      googleDriveID, 
      setGoogleDriveID, 
      isStudent, setIsStudent,
      username, setUsername,
      email, setEmail }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
