import React, { useState, useEffect } from 'react';
import './Nav.css';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const NavNotice = () => {
    const [iconColor, setIconColor] = useState('red'); 

    useEffect(() => {
        const getNotification = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/convert/download`);
                const { hasRecentAlert } = response.data;

                setIconColor(hasRecentAlert ? '#39FF14' : 'red');
            } catch (error) {
                console.error('Error fetching notification:', error);
                setIconColor('red'); 
            }
        };

        getNotification();
    }, []); 

    
    const handleIconClick = () => {
        setIconColor('black');
    };

    return (
        <li className="nav-item">
            <a className="nav-link nav-icon" href="#" onClick={handleIconClick}>
                <i className="bi bi-download" style={{ color: iconColor }}></i>
            </a>
        </li>
    );
};

export default NavNotice;
