import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PriceContext } from '../Context/PriceContext';
import './AddServices.css';
import Mount from '../Mount';
import { Helmet } from 'react-helmet';

const AddServices = () => {
  const { priceData, setPriceData } = useContext(PriceContext);
  const [services, setServices] = useState(priceData.services || {});
  const [tooltip, setTooltip] = useState({ visible: false, text: '' });
  const navigate = useNavigate();

  useEffect(() => {
    setServices(priceData.services);
  }, [priceData.services]);

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
    setServices((prevServices) => ({
      ...prevServices,
      [id]: checked,
    }));
  };

  useEffect(() => {
    setPriceData((prevData) => ({
      ...prevData,
      services,
    }));
  }, [services, setPriceData]);

  const handleSubmitOrderClick = (e) => {
    e.preventDefault();
    navigate('/upload');
  };

  const handleMouseEnter = (e, service) => {
    setTooltip({ visible: true, text: tooltips[service] || "No description available" });
  };
  
  const handleMouseLeave = () => {
    setTooltip({ visible: false, text: '' });
  };
  

  const tooltips = {
    DRR: "Select this option to initiate the review of lengthy documentation, to obtain specific information on a particular topic, or to initiate exhaustive web/database searches. This option does not include written responses.",
    Investigate: "Select this option to request a targeted investigation of searchable online databases or publicly accessible government resources.",
    Research: "Choose this option to initiate specialized research on a particular area of interest.",
    Design: "Select this option to initiate a project with a custom design, graphic, letterhead, or logo.",
    Review: "Select this option if the project requires a brief review of supporting documentation, other than a rough draft, to understand the scope or nature of this project.",
    Layout: "Choose this option if a document requires strict adherence to a legal, professional, or academic format/standard.",
    Merge: "Select this option to merge additional documents as enclosures to your main file as a single pdf for one FLAT RATE. File size may not exceed 10MB.",
    Audio: "Choose this option to submit an audio file or voice messages (.mp3 files) that when combined, does not exceed 10 minutes.",
    Video: "Select this option to have a video reviewed that does not exceed a file size of 10MB.",
    Compress: "Select this option if you require a specific file size. For example, MSPB filings, pleadings, and upload cannot exceed 10MB. ",
    Convert: "All projects are returned to the client as a .pdf; however should you desire an editable Photoshop, Adobe Illustrator, Microsoft Publisher or Microsoft Word file, please select this option so the file can be converted for your future use.",
    Consultation: "Select this option to have an audio-visual conference call scheduled within the next 72 hours.",
  };

  return (
    <Mount>
      <Helmet>
        <title>Add Services - Contranorm Media & Production</title>
      </Helmet>
    
      <div className="page">
        <div className="header_text">
        <div className="text"> Premium Services</div>
        </div>
        <div className="underline"></div>
       
        <form onSubmit={handleSubmitOrderClick}>
          <div className="services">
            {Object.keys(services).map((service) => (
                <div
                  className="service-item"
                  key={service}
                  onMouseEnter={(e) => handleMouseEnter(e, service)}
                  onMouseLeave={handleMouseLeave}
                >
                  <input
                    type="checkbox"
                    id={service}
                    checked={services[service]}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor={service}>{service}</label>
                  <div className="tooltip"></div> 
                </div>
              ))}
            <div
              className={`tooltip ${tooltip.visible ? 'visible' : ''}`}>
              {tooltip.text}
            </div>
          </div>
          <div className="submit-container_add">
            <button type="submit" className="submit">
              Next
            </button>
            <button type="button" className="submit gray" onClick={() => navigate('/price-calculator')}>
              Edit Order
            </button>
          </div>
        </form>
       
        
      </div>
    </Mount>
  );
};

export default AddServices;
