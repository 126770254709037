import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import Mount from '../Mount'; 
import './Hook.css'; 

const Hook = () => {
  const [action, setAction] = useState("Document Preparation"); 
  const navigate = useNavigate(); 
  const handleHomeClick = () => {
    navigate('/home'); 
  };

  const handleDocumentPreparationClick = () => {
    setAction("Document Preparation"); 
  };

  const handleConfidenceClick = () => {
    setAction("Confidence"); 
  };

  return (
    <Mount>
      <Helmet>
        <title>{action === "Document Preparation" ? "Document Preparation" : "Confidence"} - Contranorm Media & Production</title>
      </Helmet>
      <div className="page">
        <div className="header_text">
          <div className="text">{action === "Document Preparation" ? "Document Preparation" : "Administrative Hearings"}</div>
        </div>
        <div className="underline"></div>

        <>
          {action === "Document Preparation" ? (
            <>
            <br />
            
              <p>
                <h4>Need a Stress-Free Way to Prepare for Your Case?</h4>
                Attend any administrative hearing with confidence, knowing that every document that you've submitted has been meticulously prepared.  With Contranorm Media & Production's document preparation service, we don't just help you submit your paperwork— we craft  comprehensive arguments to validate your evidence and fortify your case.
              </p>
            </>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
             
              <p>
                <h4>
                  File Your Legal Paperwork with Confidence
                </h4>
                Preparing legal documents can be challenging, but it doesn't have to be. Let our team help you get your pleading filed quickly and accurately, so you can focus on winning your case.
                <br /><br />
                Our expert writers specialize in administrative processes from formal grievances to EEOC complaints, MSPB appeals, PFR, and Petitions for Enforcement. So, if you are a federal employee or government worker and believe that you've been treated unfairly, exercise your rights, and take action today!
              </p>
            
              <div className="wrap-text">
                Learn to&nbsp;
                <Link to="/articles" className="link-with-space">manage</Link>
                &nbsp;your own administrative filings.
              </div>
            </div>
          )}
        </>
        <div className="submit-container">
        {action === "Document Preparation" ? (
          <>
            <div className="submit gray" onClick={handleHomeClick}>Go Back</div> 
            <div className="submit" onClick={handleConfidenceClick}>Learn More</div> 
          </>
        ) : (
          <>
            <div className="submit gray" onClick={handleDocumentPreparationClick}>Go Back</div> 
            <Link to="/price-calculator" className="submit">Get Started</Link> 
          </>
        )}
      </div>
      </div>

     

    </Mount>
  );
};

export default Hook;
