import React from 'react';
import './Home.css'; 
import Header from '../Components/Header/Header';
import MainContent from '../Components/MainContent/MainContent';


const Home = () => {
  return (
    <div className= 'home-container'>
       <Header />
       <div className="stripe"></div>
       <MainContent />
      
      
    </div>
  );
};

export default Home;
