import React, { useState, useContext,  } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import axiosInstance from '../../../axiosInstance'; 
import { UserContext } from '../../../UserContext'; 
import Mount from '../Mount';
import AlertBanner from '../Banner/AlertBanner';
import "./AccountType.css"; 
import { FaBookOpen, FaGraduationCap } from "react-icons/fa";

const API_URL = process.env.REACT_APP_API_URL;




const AccountType = () => {
  
  const {
      username,
      email,
    } = useContext(UserContext);
  const [signUp, setSignUp] = useState(false);
  const [isStudent, setIsStudent] = useState(false); 
  const { signIn } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [showAlert, setShowAlert] = useState(false);

  const navigate = useNavigate();

  const handleAccountTypeClick = async (type) => {
    if (loading) return;
    const role = type === "student" ? 'student' : 'user';
    console.log (role);
    setIsStudent(role === 'student');
    setLoading(true);
    setSignUp(true);
    setShowAlert(false);
    
    if (username && email) {
      const values = {
        username,
        email,
        role
      };
      try {
        const res = await axiosInstance.post(`${API_URL}/users/signup`, values);
        if (res.data.ok) {
          alert("Check email to confirm registration and continue.");
          navigate("/price-calculator");
        } else {
          alert(res.data.message);
        }
      } catch (err) {
        console.error("Error signing up:", err);
        setShowAlert(true);
        alert(err.response?.data?.error || "An unexpected error occurred");
      }
    }
    setLoading(false);
    setSuccessMessage();
  };

  return (
          <div className="account-type-page">
            <div className='account-type-container'>
                <div className="account-type-text">Account Options</div>
                <p>Which resources do you want to access?</p>
                <div className='container-options'>
                    <div className='account-options'>
                        <button
                            type="button"
                           
                            onClick={() => handleAccountTypeClick("student")}
                            disabled={loading}
                            >
                            <FaBookOpen className="icon" />
                            <span>Student</span>
                        </button>
                    </div>
                    <div className='account-options'>
                        <button
                            type="button"
                            
                            onClick={() => handleAccountTypeClick("user")}
                            disabled={loading}
                            >
                            <FaGraduationCap className="icon" />
                            <span>Professional</span>
                        </button>

                    </div>

                </div>
                
                
        
       
             </div>
              <AlertBanner 
                showAlert={showAlert} 
                showSaveButton={false} 
                handleCancel={() => setShowAlert(false)}>
                <div style={{ display: 'flex',  width: '100%' }}>
                  <span style={{ flex: 1 }}>It appears that you may be in a geo-restricted area or this feature has been temporarily disabled by your device.</span>
              
                
                </div>
              </AlertBanner>
          </div>
         
            
   
      );
    };

export default AccountType;
