import React, { useState, useEffect, useContext } from 'react';
import './FBFooter.css';
import $ from 'jquery'; 
import axiosInstance from '../../../axiosInstance';
import { UserContext } from '../../../UserContext';

const API_URL = process.env.REACT_APP_API_URL;
const STARS_API = `${API_URL}/api/stars`;
const DOWNLOAD = `${API_URL}/api/convert/download`;


const FBFooter = ({ pdfUrl, flipbookRef, googleDriveID, addStickyNote, pages, currentPage,  totalPages,  toggleSidebar, sidebarVisible, soundEnabled, toggleSound, handlePageChange, footerPageRange, setFooterPageRange, handleSaveAndFeedback }) => {
  
  const [stars, setStars] = useState(0);
  const [showStarRating, setShowStarRating] = useState(false);
  const [isRatingSubmitted, setIsRatingSubmitted] = useState(false); 
  const { user } = useContext(UserContext);
  
  const handlePrevPage = () => {
    const flipbook = $(flipbookRef.current);
    const prevPage = currentPage - 1;
    
    if (prevPage >= 1) {
      flipbook.turn('previous');
      handlePageChange(prevPage); 
    } else {
      console.warn('Already on the first page.');
    }
  };

  const handleNextPage = () => {
    const flipbook = $(flipbookRef.current);
    const nextPage = currentPage + 1; // Adjust based on current page state
    
    if (nextPage <= pages.length) {
      flipbook.turn('next');
      handlePageChange(nextPage); // Apply centering and size when going forward
    } else {
      console.warn('Already on the last page.');
    }
  };

  
  const handleDownload = async () => {
    try {
      
      const storedPdfUrl = pdfUrl || sessionStorage.getItem('googleDriveLink');
  
      if (!storedPdfUrl) {
        console.error('No PDF URL available for download.');
        return;
      }
  
      const response = await fetch(storedPdfUrl);
      const blob = await response.blob(); 
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob); 
  
      link.href = url;
      link.download = `${user.email}.pdf`; 
   
      document.body.appendChild(link);
    
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url); 
      if (user && user.email&& googleDriveID) {
        await logDownload(googleDriveID, user.email);
      } else {
        console.error('User email is not available.');
      }
    } catch (error) {
      console.error('Error during download:', error);
    }
  };
  
  
  const logDownload = async (googleDriveID, email) => {
    const downloadData = {
      email: email,
      googleDriveID: googleDriveID, 
      timestamp: new Date().toISOString(),
    };
  
    try {
      await axiosInstance.post(`${DOWNLOAD}`, downloadData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      console.error('Error logging download:', error);
      throw error;
    }
  };

  const handleStarClick = async (index) => {
    if (isRatingSubmitted) return;

    const rating = index + 1;
    setStars(rating);

    try {
      const response = await sendStarRating(rating);

      if (response.status === 201 || response.status === 200) {
        setIsRatingSubmitted(true);
        setShowStarRating(false); 
      }
    } catch (error) {
      console.error('Error sending rating:', error);
    }
  };

  const sendStarRating = async (rating) => {
    if (!user || !user.email) {
      console.error('User email is not available.');
      return;
    }
    const ratingData = {
      email: user.email,
      pdfUrl: pdfUrl,
      rating: rating,
      date: new Date().toISOString(),
    };

    try {
      return await axiosInstance.post(STARS_API, ratingData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      console.error('Error sending rating data:', error);
      throw error;
    }
  };

  useEffect(() => {
    if (currentPage === 1) {
      setFooterPageRange(`${currentPage}-${currentPage} of ${pages.length}`);
    } else if (currentPage === pages.length) {
      setFooterPageRange(`${currentPage}-${currentPage} of ${pages.length}`);
    } else {
      const nextPage = currentPage + 1;
      setFooterPageRange(`${currentPage}-${nextPage} of ${pages.length}`);
    }
  }, [currentPage, pages.length, setFooterPageRange]);
  
 

  return (
    <div className="flipbook-footer">
      <button onClick={handleDownload}><i className="fas fa-download"></i></button>
      <button onClick={toggleSidebar}>
      {sidebarVisible ? '' : ''}<i className="fa-solid fa-table"></i></button>
      <button onClick={addStickyNote}><i className="fa-solid fa-notes-medical" /></button>
      <button onClick={handleSaveAndFeedback}><i className="fa-solid fa-save" /></button>
      <button onClick={handlePrevPage}><i className="fa-solid fa-backward"></i></button>
      <button onClick={handleNextPage}><i className="fa-solid fa-forward"></i></button>
      

      <button onClick={() => setShowStarRating(!showStarRating)}>
        <i className={`fas fa-star ${stars > 0 ? 'selected' : ''}`}></i>
      </button>

      {showStarRating && (
        <div className="star-rating">
          {[...Array(5)].map((_, index) => (
            <i
              key={index}
              className={`fas fa-star ${index < stars ? 'selected' : ''}`}
              onClick={() => handleStarClick(index)}
              style={{ pointerEvents: isRatingSubmitted ? 'none' : 'auto' }} 
            />
          ))}
        </div>
      )}

      <div className="page-count">
      <span>{footerPageRange}</span>
      </div>
    </div>
  );
};

export default FBFooter;
