import React from 'react';
import './Logo.css';
import logo_silver from '../Assets/Images/logo_silver.png';

function Logo() {
    

  return (
    <div className="logo">
        <a href="/" className="d-flex align-items-center">
         <img src={logo_silver} alt="Logo" />
         <span className="d-none d-lg-block"></span>
         </a>
        
    </div>
    
  );
}

export default Logo
