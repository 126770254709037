import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../axiosInstance';
import styles from './Upload.module.css';
import { Helmet } from 'react-helmet';
import Mount from '../Mount';
import { PriceContext } from '../Context/PriceContext';
import Validation from '../LoginSignup/LoginSignupValidation';
import AlertBanner from '../Banner/AlertBanner';

const API_URL = process.env.REACT_APP_API_URL;

const Upload = () => {
  const [files, setFiles] = useState([]);
  const [totalSize, setTotalSize] = useState(0);
  const [showOverlay, setShowOverlay] = useState(false);
  const [instructions, setInstructions] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const { setPriceData } = useContext(PriceContext);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const handleFileChange = (event) => {
    const uploadedFiles = event.target.files;
    let newFiles = [...files];
    let total = totalSize;

    for (let i = 0; i < uploadedFiles.length; i++) {
      const file = uploadedFiles[i];
      const fileSizeMB = file.size / (1024 * 1024);

      if (!isValidFileType(file.name)) {
        alert(`File type not supported: ${file.name}`);
        continue;
      }

      if (total + fileSizeMB > 10) {
        alert(`Total file size exceeds the 10MB limit. You can only upload up to ${(10 - total).toFixed(2)} MB more.`);
        break;
      }

      newFiles.push(file);
      total += fileSizeMB;
    }

    setFiles(newFiles);
    setTotalSize(total);
  };

  const isValidFileType = (fileName) => {
    const allowedExtensions = ['.jpeg', '.jpg', '.png', '.docx', '.doc', '.xlsx', '.xls', '.pdf', '.mp3', '.mp4'];
    const extension = fileName.substring(fileName.lastIndexOf('.')).toLowerCase();
    return allowedExtensions.includes(extension);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (files.length === 0) {
      alert('Please select at least one file to upload.');
      return;
    }

    setShowOverlay(true);
  };

  const handleOverlaySubmit = async () => {
    if (!email) {
      alert('Please enter your  primary email.');
      return;
    }

    const validationErrors = Validation({ email, instructions });
    if (validationErrors.email || !instructions) {
      alert('Please provide complete and accurate information.');
      return;
    }


    try {
      const statusResponse = await axiosInstance.get(`${API_URL}/users/status`, { params: { email } });
      const statusData = statusResponse.data;

      if (statusData.status === 'blocked') {
        setAlertMessage(` ${statusData.reason}`);
        setShowAlert(true);

        setTimeout(() => {
          navigate('/login-signup');
        }, 5000);

        return;
      }

      setShowOverlay(false);

      const formData = new FormData();
      files.forEach(file => {
        formData.append('files', file);
      });
      formData.append('instructions', instructions);
      formData.append('email', email);

      setPriceData(prevData => ({ ...prevData, email, instructions, uploadedFiles: files }));

      const response = await axiosInstance.post(`${API_URL}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        const uploadedFileNames = response.data.fileIDs;
        setPriceData(prevData => ({
          ...prevData,
          uploadedFiles: uploadedFileNames,
        }));
        alert('Files uploaded successfully!');
        setFiles([]);
        setTotalSize(0);
        setInstructions("");
        setEmail("");
        navigate('/confirmation-payment');
      } else {
        alert('Failed to upload files');
      }
    } catch (error) {
      console.error('Error checking user status or uploading files:', error);
      alert('Oops! Your device appears to have a connectivity issue. Please check your firewall settings, clear your cookie cache, or verify your internet connection.');
    }
  };

  return (
    <Mount>
      <Helmet>
        <title>Upload - Contranorm Media & Production</title>
      </Helmet>
      <div className="page">
        <div className={styles.container}>
          {!showOverlay ? (
            <>
              <div className={styles.header}>
                
                
                
                <div className={styles.header_text}>Supporting Documentation</div>
                <div className={styles.underline}></div>
              </div>
              <p>Upload all files required to process this order.</p>
              
              <div className={styles.upload_container}>
                <form onSubmit={handleSubmit} encType="multipart/form-data">
                  <input
                    type="file"
                    id="file-input"
                    accept=".jpeg, .jpg, .png, .docx, .doc, .xlsx, .xls, .pdf, .mp3, .mp4"
                    multiple
                    onChange={handleFileChange}
                  />
                  <label htmlFor="file-input">Select Files</label>
                  <div id="num-of-files">{files.length} Files Selected ({totalSize.toFixed(2)} MB)</div>
                  <ul id="files-list" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                    {files.map((file, index) => (
                      <li key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <p style={{ color: '#5d1073', fontSize: '12px' }}>{file.name}</p>
                        <p style={{ color: 'rgb(223, 154, 162)', fontSize: '10px' }}>{(file.size / 1024).toFixed(1)} KB</p>
                      </li>
                    ))}
                  </ul>
                  <button type="submit">Upload</button>
                </form>
              </div>
            </>
          ) : (
            <>
              <div className={styles.overlay_header}>
              <h2 style={{ marginTop: '55px',fontWeight: '700', fontSize: '2.2rem', 
               }}>Share Your Vision</h2>
              
              <div className={styles.overlay_underline}></div>
              </div>
              <> <p></p></>
              <div className={styles.overlay}>
                <div className={styles.overlayContent}>
                <textarea value={instructions}
                onChange={(e) => {
                  if (e.target.value.length <= 500) {
                    setInstructions(e.target.value);
                  }
                }}
                placeholder="Instructions:"
                required>
                

                </textarea>
                  <input
                    type="email"
                    placeholder="Primary Email:"
                    value={email}
                    onChange={(e) => setEmail(e.target.value.toLowerCase())}
                    onPaste={(e) => e.preventDefault()} 
                    onCopy={(e) => e.preventDefault()} 
                    required
                  />
                 <button className='overlay_button'
                  onClick={handleOverlaySubmit}
                  style={{
                    backgroundColor: '#5d1073',
                    color: '#fff',
                    width: '100x',
                    padding: '10px 20px',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s ease',
                    
                  }}
                >
                  Confirm 
                </button>

                </div>
              </div>
            </>
          )}
        </div>

        <AlertBanner showBanner={showAlert} handleCancel={() => setShowAlert(false)}>
          <div>
            <span style={{ flex: 1 }}>This account has been temporarily blocked due to the <strong>{alertMessage}</strong></span>
         
          </div>
        </AlertBanner>
      </div>
    </Mount>
  );
};

export default Upload;
