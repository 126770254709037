import React, { useContext, useEffect, useState, useRef } from 'react';
import './SupportServices.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from '../../../UserContext'; 


import { PriceContext } from '../Context/PriceContext';
import axiosInstance from '../../../axiosInstance';
import moment from 'moment-timezone';
const API_URL = process.env.REACT_APP_API_URL;
const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;


const SupportServices = () => {
    const { user } = useContext(UserContext); 
    const isMounted = useRef(true); 
    const [initialCost, setInitialCost] = useState(0);
    const [sdkLoaded, setSdkLoaded] = useState(false);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const { priceData, setPriceData } = useContext(PriceContext);
    const [payNow, setPayNow] = useState(false);
    const navigate = useNavigate(); 
    const location = useLocation();
  
    const updatePaymentStatus = (status) => {
      setPriceData((prev) => ({ ...prev, paymentStatus: status }));
    };

    const loadPayPalScript = (cost) => {
      if (sdkLoaded || document.querySelector('#paypal-sdk-script')) return;
        const script = document.createElement('script');
        script.src = `https://www.paypal.com/sdk/js?client-id=${PAYPAL_CLIENT_ID}&currency=USD`;
        script.async = true;
        script.onload = () => {
          setSdkLoaded(true);
          if (!window.paypal) {
            return;
          }
          window.paypal.Buttons({
            createOrder: (data, actions) => {
    
              return actions.order.create({
                purchase_units: [{
                  amount: {
                    value: cost.toFixed(2),
                  },
                }],
              });
            },
            onApprove: (data, actions) => {
              return actions.order.capture().then((details) => {
                alert('Transaction completed successfully by ' + details.payer.name.given_name);
                setPaymentSuccess(true);
                updatePaymentStatus('SUCCESS');
                
              });
            },
            onError: (err) => {
              console.error('PayPal Checkout error:', err);
              alert('An error occurred. Please try your purchase again.');
              updatePaymentStatus('FAILED');
            },
          }).render('#paypal-buttons-container').catch((err) => {
            console.error('Error rendering PayPal buttons:', err);
          });
        };
        document.body.appendChild(script);
      };

          

  
      useEffect(() => {
        isMounted.current = true;
        return () => {
          isMounted.current = false; 
        };
      }, []);

      

      
 
  const handlePayNow = (cost) => {
    if (!user) {
      localStorage.setItem('returnUrl', location.pathname);
      navigate('/login-signup');
      return; 
    }
    setInitialCost(cost);
    setPayNow(true);
    loadPayPalScript(cost);
  };
  
 
    
  const enableSupportServices = async () => {
    try {
      const email = user.email.trim().toLowerCase();

      const response = await axiosInstance.post(`${API_URL}/users/support-services`, {
        email: email,  
      });

  
          if (response.status === 200) {
            alert('Support enabled.');
            navigate('/dashboard');
          } else {
            console.error('Failed to enable support services');
          }
        } catch (error) {
          console.error('Error enabling support services:', error);
        }
      };
      

      useEffect(() => {
        if (paymentSuccess) {
          enableSupportServices();
        }
      }, [paymentSuccess]);
    
 

 

  return (
    <div className="support-page">
      <div className="support-container">
        {!payNow && (
          <>
            <div className="support-option">
              <i className="fas fa-comment"></i>
              <div className="support-details">
                <div className="support-title">Basic Support</div>
                <div className="support-description">
                  Submit a support ticket by email with specific technical or user interface concerns for the IT Desk. Estimated response time greater than or equal to 8 hours.
                </div>
                <div className="support-price">
                    <span className="support-price" onClick={() => handlePayNow(107.88, 'Basic Support')}>$8.99/month</span>

                </div>
               
              </div>
            </div>

            <div className="support-option">
              <i className="fas fa-microphone"></i>
              <div className="support-details">
                <div className="support-title">Standard Support</div>
                    <div className="support-description">
                    Enable the live chat feature on your dashboard and speak with the Support Desk during office hours from 5am to 3pm EST.
                    </div>
                    <div className="support-price">
                        <span onClick={() => handlePayNow(195, 'Standard Support')}>$16.25/month</span>

                    </div>      
                </div>
            </div>

            <div className="support-option">
              <i className="fas fa-headset"></i>
              <div className="support-details">
                <div className="support-title">Premium Support</div>
                <div className="support-description">
                  24/7 phone assistance, live chat, audio messaging, and emails to the Support Desk.
                  We offer different levels of engagement to meet your needs, whether you have a handful of questions or want expert assistance with a large project. Flexible pay-as-you-go plans starting at $99/mo.
                    <br /><br />
                    This is an estimated pay-as-you-go minimum service fee, based on a 10% minumum monthly usage. 
                </div>
                <div className="support-price"></div>
                <button className="PayNow" onClick={() => handlePayNow(99.0, 'Premium Support')}>Access</button>
              </div>
            </div>
          </>
        )}
        {payNow && <div id="paypal-buttons-container" className={!payNow ? "hide" : ""}></div>}
      </div>
    </div>
  );
};

export default SupportServices;
