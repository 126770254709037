import React, { useState, useEffect, useRef } from 'react';
import $ from 'jquery';
import 'turn.js';
import './FlipBook.css';
import * as pdfjsLib from 'pdfjs-dist';
import { WorkerMessageHandler } from 'pdfjs-dist/build/pdf.worker.min.mjs';
import pageTurn from './Assets/pageTurn.mp3';
import StickyNote from './StickyNote/StickyNote';
import FBFooter from './Footer/FBFooter';
import FlipBookSidebar from './Sidebar/FlipBookSidebar';
import { getRandomColor } from '../Utilities/RandomColorGen.js'; 
import { AtomSpinner } from 'react-epic-spinners';

const API_URL = process.env.REACT_APP_API_URL;


pdfjsLib.GlobalWorkerOptions.workerSrc = URL.createObjectURL(new Blob([WorkerMessageHandler], { type: 'application/javascript' }));

const FlipBook = ({ pdfUrl, stickyNote, setStickyNote, googleDriveID, handleSaveAndFeedback }) => {
  const audioRef = useRef(new Audio(pageTurn));
  const [soundEnabled, setSoundEnabled] = useState(true);
  
  const [pages, setPages] = useState([]);
 
  const [loading, setLoading] = useState(true);
  const flipbookRef = useRef(null);
  const [flipbookInitialized, setFlipbookInitialized] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [sidebarContent, setSidebarContent] = useState('thumbnails');
  const [activeThumbnail, setActiveThumbnail] = useState(null);

  const maxWidth = 800;
  const maxHeight = 510;

  const [footerPageRange, setFooterPageRange] = useState(`${currentPage}-${currentPage} of ${pages.length}`);


  useEffect(() => {
    const loadPdf = async () => {
      try {
        const pdf = await pdfjsLib.getDocument(pdfUrl).promise;
        const numPages = pdf.numPages;

        const renderPromises = [];
        for (let i = 1; i <= numPages; i++) {
          renderPromises.push(pdf.getPage(i).then(page => {
            const viewport = page.getViewport({ scale: 4 });
            const canvas = document.createElement('canvas');
            canvas.width = viewport.width;
            canvas.height = viewport.height;
            const context = canvas.getContext('2d');

            return page.render({ canvasContext: context, viewport }).promise.then(() => canvas.toDataURL());
          }));
        }

        const pagesData = await Promise.all(renderPromises);
        setPages(pagesData);
        setLoading(false);
        setTotalPages(numPages);
      } catch (error) {
        console.error('Error loading PDF:', error);
      }
    };

    loadPdf();
  }, [pdfUrl]);
  
  const centerDouble = () => {
    const viewportWidth = window.innerWidth - 150; 
    const singlePageWidth = maxWidth / 2;
    const marginLeft = Math.max(0, (viewportWidth - singlePageWidth) / 2.5);
  
    return {
      transform: `translateX(${marginLeft}px)`, 
      transition: 'transform 0.5s ease-out',  
    };
  };
  
  const centerSingle = () => {
    const viewportWidth = window.innerWidth - 150;
    const singlePageWidth = maxWidth;
    const marginLeft = Math.max(0, (viewportWidth - singlePageWidth) + 55);
  
    return {
      transform: `translateX(${marginLeft}px)`,
      transition: 'transform 0.5s ease-out',
    };
  };
  
  useEffect(() => {
    if (!loading && !flipbookInitialized) {
      const flipbook = $(flipbookRef.current);
  
      if (flipbook.length && !flipbook.data('turn')) {
        const setDisplayMode = (page) => {
          if (page === 1 || page === pages.length) {
            flipbook.turn('display', 'single');
            flipbook.turn('size', maxWidth / 2, maxHeight);
            const centeringSingle = centerSingle();
            flipbook.css(centeringSingle);
          } else {
            flipbook.turn('display', 'double');
            flipbook.turn('size', maxWidth, maxHeight);
            const centeringDouble = centerDouble();
            flipbook.css(centeringDouble);
          }
        };
  
        flipbook.turn({
          width: maxWidth,
          height: maxHeight,
          autoCenter: false, 
          acceleration: true,
          duration: 1900,
          display: 'single',
          gradients: true,
          elevation: 50,
          pages: pages.length,
          when: {
            turning: (event, page, view) => {
           
              setDisplayMode(page); 
  
              if (page < 1 || page > pages.length) {
                console.error('Invalid page number:', page);
                event.preventDefault();
                return;
              }
  
              setCurrentPage(page);
              setActiveThumbnail(page - 1);
  
              if (soundEnabled) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
                audioRef.current.play().catch(error => console.error('Audio playback error:', error));
              }
            },
            turned: (event, page, view) => {
              
              setDisplayMode(page);
            }
          }
        });
  
        setFlipbookInitialized(true); 
      }
  
      return () => {
        if (flipbook.length && flipbook.data('turn')) {
          flipbook.turn('destroy');
        }
      };
    }
  }, [loading, flipbookInitialized, pages, currentPage, soundEnabled]);

  useEffect(() => {
    if (currentPage === 1) {
      const flipbook = $(flipbookRef.current);
      const centeringSingle = centerSingle(); 
      flipbook.css(centeringSingle);
    }
  }, [currentPage]);
  
  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const addStickyNote = () => {
    const flipbookContainer = flipbookRef.current;
    const rect = flipbookContainer.getBoundingClientRect();
  
    // Get the scaling factor applied by the flipbook
    const flipbookScaleX = flipbookContainer.scrollWidth / maxWidth;
    const flipbookScaleY = flipbookContainer.scrollHeight / maxHeight;
  
    // Generate random position within the visible area of the flipbook
    const newNote = {
      id: Date.now(),
      text: '',
      // Adjust random position based on the scaling
      x: (Math.random() * (rect.width - 200)) / flipbookScaleX, 
      y: (Math.random() * (rect.height - 200)) / flipbookScaleY, 
      color: getRandomColor(),
      page: currentPage,
      date: new Date()
    };
  
    setStickyNote([...stickyNote, newNote]);
  };
  
  

  const handleDragStart = (e, id) => {
    e.dataTransfer.setData('text/plain', id);
  };
  
  const handleDrop = (e) => {
    e.preventDefault();
    const id = e.dataTransfer.getData('text');
    const note = stickyNote.find(note => note.id === parseInt(id));
  
    if (note) {
      const flipbookContainer = flipbookRef.current;
      const rect = flipbookContainer.getBoundingClientRect();
  
      const flipbookScaleX = flipbookContainer.scrollWidth / maxWidth;
      const flipbookScaleY = flipbookContainer.scrollHeight / maxHeight;
  
      const updatedNote = {
        ...note,
        x: (e.clientX - rect.left) / flipbookScaleX, 
        y: (e.clientY - rect.top) / flipbookScaleY,  
      };
  
      
      setStickyNote(stickyNote.map(note => (note.id === parseInt(id) ? updatedNote : note)));
    }
  };
  
  
  const handleDragOver = (e) => {
    e.preventDefault();
  };
  
  
  const handleThumbnailClick = (page) => {
    if (page >= 0 && page < pages.length) { 
      setActiveThumbnail(page);
  
      const flipbook = $(flipbookRef.current);
      if (page === 0) {
        
        flipbook.turn('display', 'single');
        flipbook.turn('size', maxWidth / 2, maxHeight); 
        const centeringSingle = centerSingle();  
        flipbook.css(centeringSingle);
      } else {
        
        flipbook.turn('display', 'double');
        flipbook.turn('size', maxWidth, maxHeight); 
        const centeringDouble = centerDouble();  
        flipbook.css(centeringDouble);
      }
  
      flipbook.turn('page', page + 1);
  
    } else {
      console.error('Invalid page number:', page + 1);
    }
  };

  const handlePageChange = (page) => {
    const flipbook = $(flipbookRef.current);
    const pageWidth = maxWidth;
    const pageHeight = maxHeight;
  
    setCurrentPage(page);
  
    if (page === 1 || page === pages.length) {
      flipbook.turn('display', 'single');
      flipbook.turn('size', pageWidth / 2, pageHeight);
      flipbook.css(centerSingle(pageWidth / 2));
    } else {
      flipbook.turn('display', 'double');
      flipbook.turn('size', pageWidth, pageHeight);
      flipbook.css(centerDouble(pageWidth));
    }
  
    flipbook.turn('page', page);
    if (page === 1) {
      setFooterPageRange(`${page}-${page} of ${pages.length}`);
    } else if (page === pages.length) {
      setFooterPageRange(`${page}-${page} of ${pages.length}`);
    } else {
      const nextPage = page + 1;
      setFooterPageRange(`${page}-${nextPage} of ${pages.length}`);
    }
  };
  

  return (
    <>
      <div ref={flipbookRef}  className="flipbook-container">
        {loading ? (
          <div>
            <AtomSpinner color="#ffffff" size={60} /> 
          </div>
          ) : (
            <div className="flipbook-wrapper">
              <div className="flipbook" ref={flipbookRef}>
                {pages.map((src, page) => (
                  <div key={page} className="flipBookpage"onDragOver={handleDragOver} onDrop={handleDrop}>
                    <img src={src} alt={`Page ${page + 1}`} />
                    {page % 1 === 0 && <div className="page-gradient left-gradient"></div>}
                    {(page + 1) % 2 === 0 && <div className="page-gradient right-gradient"></div>}
                  </div>
                ))}
              </div>
            </div>
          
        )}
              
      </div>
      <FlipBookSidebar
        pages={pages}
        activeThumbnail={activeThumbnail}
        handleThumbnailClick={handleThumbnailClick}
        sidebarVisible={sidebarVisible}
        toggleSidebar={toggleSidebar}
        sidebarContent={sidebarContent}
        centerSingle={centerSingle}
        centerDouble={centerDouble}
    
      />
      <StickyNote
        stickyNote={stickyNote}
        setStickyNote={setStickyNote}
        currentPage={currentPage}
        flipbookRef={flipbookRef}
        getRandomColor={getRandomColor}
      />
      <FBFooter
        pdfUrl={pdfUrl} 
        flipbookRef={flipbookRef}
        pages={pages} 
        currentPage={currentPage}
        totalPages={totalPages}
        addStickyNote={addStickyNote}
        soundEnabled={soundEnabled}
        toggleSound={() => setSoundEnabled(!soundEnabled)}
        toggleSidebar={toggleSidebar}
        sidebarVisible={sidebarVisible}
        googleDriveID={googleDriveID}
        handleSaveAndFeedback={handleSaveAndFeedback}
        handlePageChange={handlePageChange}
        setFooterPageRange={setFooterPageRange} 
        footerPageRange={footerPageRange}
        
      />
  </>
  );
};

export default FlipBook;
