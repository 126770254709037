import React from 'react';

const NavItem = ({ nav, onClick, icon }) => {
  return (
    <li className="nav-item" key={nav._id} onClick={onClick}>
      <a className="nav-link collapsed" href="#">
        {icon} 
        <span>{nav.name}</span>
      </a>
    </li>
  );
};

export default NavItem;
