import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import Mount from '../Mount'; 
import './CGI.css';

const CGI = () => {
  const [action, setAction] = useState("CGI");
  const navigate = useNavigate(); 

  const handleHomeClick = () => {
    navigate('/home'); 
  };

  const handleCGIClick = () => {
    setAction("CGI"); 
  };

  const handleLearnMoreClick = () => {
    setAction("LearnMore"); 
  };

  const handleExploreClick = () => {
    navigate('/error');
  };

  return (
    <Mount>
      <Helmet>
        <title>{action === "CGI" ? "CGI" : "Explore"} - Contranorm Media & Production</title>
      </Helmet>
      <div className="page">
      <div className="header_text">
          <div className="text">{action === "CGI" ? "Content Creation" : "Digital Technologies"}</div>
        </div>
        <div className="underline"></div>
        
        {action === "CGI" ? (
          <>
 
            <p>
              <h4>   </h4>
              Did you know that our talented independent creators can craft personalized 3D content just for you?
              Explore Contranorm Media & Production's Custom Content Creation Service. Whether you need digital animations for your small business or support with personal projects, our team is ready to help accelerate your project. We make it easier to bring your vision to life—without the stress of searching for skilled animators or 3D modelers. 
            </p>
          </>
        ) : (
        
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}> 
          
         
            <p>
              <h4>
                  Let our skilled 3D artists bring your vision to life.
                </h4>
                  Finding the right way to express your ideas, present a cool concept, or convey information can be time consuming and require a specialized skill set that you may not have mastered just yet, but we've done all the heavy lifting for you, so all you'll have to do is focus on sharing your content. 
            </p>
            <br />
    
          
            <div className="wrap-text">
              Check out what we &nbsp;
              <Link to="/error" className="link-with-space">create.</Link>
             
            </div>
          </div>
        )}
         <div className="submit-container">
                {action === "CGI" ? (
                  <>
                    <div className="submit gray" onClick={handleHomeClick}>Go Back</div> 
                    <div className="submit" onClick={handleLearnMoreClick}>Learn More</div> 
                  </>
                ) : (
                      <>
                        <div className="submit gray" onClick={handleCGIClick}>Go Back</div> 
                        <div className="submit" onClick={handleExploreClick}>Explore</div> 
                      </>
                    )}
                </div>
      </div>
    </Mount>
  );
};

export default CGI;
