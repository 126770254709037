import React, { useState, useEffect } from 'react';
import { FaVolumeUp, FaPause } from 'react-icons/fa'; // Import icons

const AudioSection = ({ audioUrl, onPlay, isPlaying }) => {
  const [audio, setAudio] = useState(null);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);

  useEffect(() => {
    const newAudio = new Audio(audioUrl);
    setAudio(newAudio);

    return () => {
      newAudio.pause();
      setAudio(null);
    };
  }, [audioUrl]); 

  useEffect(() => {
    if (isPlaying && audio) {
      audio.play().catch((err) => console.error('Audio play error:', err));
      setIsAudioPlaying(true);
    } else if (!isPlaying && audio) {
      audio.pause();
      setIsAudioPlaying(false);
    }
  }, [isPlaying, audio]); 

  const handlePlayPause = () => {
    if (isAudioPlaying) {
      audio.pause();
      setIsAudioPlaying(false);
    } else {
      onPlay(audioUrl); // Notify the parent that this audio is playing
      audio.play().catch((err) => console.error('Audio play error:', err));
      setIsAudioPlaying(true);
    }
  };

  const iconColor = isAudioPlaying ? '#757171' : '#ada8a8';

  return (
    <div>
    
      <button 
        onClick={handlePlayPause}
        style={{
          background: 'none', 
          border: 'none', 
          padding: 0, 
          cursor: 'pointer', 
          display: 'inline-flex', 
      
          alignItems: 'center', 
          marginLeft: '25px'
          
        }}
      >
    
        {isAudioPlaying ? <FaPause size={16} style={{ color: iconColor }} /> : <FaVolumeUp size={16} style={{ color: iconColor }} />}

        
      </button>
    </div>
  );
};

export default AudioSection;
