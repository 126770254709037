import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './../../admin/AdminApp.css'; 
import './Policies.css';
import { Helmet } from 'react-helmet';
import Header from '../Components/Header/Header';

import AudioSection from '../Components/Audio/AudioSection';
import SideBar from './../../admin/Components/SideBar';
import {FaTimes, FaCheck} from 'react-icons/fa';

function Policies() {
  const [audioPlaying, setAudioPlaying] = useState('');
  const [iconPosition, setIconPosition] = useState(null);
  const [isIconVisible, setIsIconVisible] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [showText, setShowText] = useState(window.innerWidth > 920);
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate('/support-services');
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 920) {
        setCollapsed(true); 
      } else {
        setCollapsed(false);  
      }
      setShowText(window.innerWidth > 920); 
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
 
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const filterCondition = (navItem) => {
    return navItem._id >= 100 && navItem._id <= 199; 
  };

  
  const handleToggleSidebar = () => {
    setCollapsed(prevState => !prevState);
  };

  const centeredTextWrapperStyle = {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    overflowWrap: 'normal',
    marginLeft: '5px',
  };
  
  
  const centeredNumeralStyle = {
    diplay: 'flex',
    flexDirection: 'column',
    marginTop:'10px',
    marginBottom: '-18px',
    alignItems: 'justify',
    paddingLeft: '13%',
    wordWrap: 'beak-word',
    paddingRight: '10%',
    color: '#ada8a8',
    fontSize: '1rem'
  };
 
  const handlePlayAudio = (audioUrl) => {
    // If the same audio is clicked, pause it
    if (audioUrl === audioPlaying) {
      setAudioPlaying('');
    } else {
      setAudioPlaying(audioUrl);
    }
  };
  const visionUrl = "https://mcusercontent.com/b4930469a53bb98c20ccf28fa/files/e69a3e2b-29d1-7b8c-9a09-33a1b4278499/About_Us.mp3";
  const riskUrl = "https://mcusercontent.com/b4930469a53bb98c20ccf28fa/files/05cc3d95-a2a6-6865-6b09-b672b5e993e1/Terms_Conditions_Risk.mp3";
  const ownershipUrl ="https://mcusercontent.com/b4930469a53bb98c20ccf28fa/files/67d67947-c1bf-369d-39a2-b9a59fa5eee7/Ownership.mp3";
  const definitionUrl="https://mcusercontent.com/b4930469a53bb98c20ccf28fa/files/d86abfe2-a448-9fa4-5022-6be16146fa5e/Terms_Definitions.mp3";
  const loginUrl="https://mcusercontent.com/b4930469a53bb98c20ccf28fa/files/5c2d31b0-f1eb-9840-a52c-c72835f311ef/Login_Registration.mp3";
  const refundUrl="https://mcusercontent.com/b4930469a53bb98c20ccf28fa/files/009b0ae2-8638-aad7-5013-a58ceb3008f5/Payments_Refunds.mp3";
  const orderStatusUrl="https://mcusercontent.com/b4930469a53bb98c20ccf28fa/files/93097778-db86-07b1-1acc-ed7cd95a653a/Order_Status.mp3";
  const timestampUrl="https://mcusercontent.com/b4930469a53bb98c20ccf28fa/files/0551096e-f9e6-2d0c-626a-ecd49bf14982/Timestamps_Extensions.mp3";
  const projectCompletionUrl="https://mcusercontent.com/b4930469a53bb98c20ccf28fa/files/2f283a86-d2fb-e7de-a281-7e95470bf0bd/Project_Completion.mp3";
  const subscriptionUrl ="https://mcusercontent.com/b4930469a53bb98c20ccf28fa/files/a83c4742-820a-d94c-c345-888e1bdd8a7e/Subscription.mp3";
  const thirdPartyUrl ="https://mcusercontent.com/b4930469a53bb98c20ccf28fa/files/626f971a-9b20-cc9f-1ee0-4f8377d37a56/ThirdParty.mp3";
  const propertyRightsUrl ="https://mcusercontent.com/b4930469a53bb98c20ccf28fa/files/33b0333e-7e5f-914e-0312-26fa536c9c62/PropertyRights_Waiver.mp3.mp3";
  const disputesUrl ="https://mcusercontent.com/b4930469a53bb98c20ccf28fa/files/eb643ff6-b87e-dd33-83d4-4294677e0418/Disputes.mp3";
  const indemnificationUrl ="https://mcusercontent.com/b4930469a53bb98c20ccf28fa/files/2a1e9495-d3c4-2b6d-04b1-ba1c4f9d14dd/Indemnification.mp3";
  const conductUrl="https://mcusercontent.com/b4930469a53bb98c20ccf28fa/files/8d61f6b1-f51c-3663-2da8-72bc9fa2c08c/Conduct.mp3";
  const privacyUrl="https://mcusercontent.com/b4930469a53bb98c20ccf28fa/files/2ac7a52f-aad4-df6c-b95f-2175a726b0ab/Privacy_Waiver.mp3";
  const dataUrl="https://mcusercontent.com/b4930469a53bb98c20ccf28fa/files/ece553d8-3c8b-b6b0-d799-7255869aa726/How_We_Use_Your_Data.mp3";
  const cookiesUrl ="https://mcusercontent.com/b4930469a53bb98c20ccf28fa/files/7310a162-7bf5-d0ef-e025-8e7f20a1c5c1/Cookies.mp3";
  const rightsUrl ="https://mcusercontent.com/b4930469a53bb98c20ccf28fa/files/ed995320-b03e-d803-e347-06dc79db7a3a/Rights_Request.mp3";
  const confidentialityUrl="https://mcusercontent.com/b4930469a53bb98c20ccf28fa/files/a7a3f281-f82f-90f6-a054-a973045ca067/Confidentiality.mp3";
  const supportUrl="https://mcusercontent.com/b4930469a53bb98c20ccf28fa/files/86983191-ab0d-c46f-b674-8b5a288d27e7/Support.mp3";
  const copyrightUrl="https://mcusercontent.com/b4930469a53bb98c20ccf28fa/files/a1eea94c-9b39-3158-2a2f-fb3dd4b75416/Copyright_DCMA.mp3";

 
 
  return (
    <div className="blog-container">
      <Helmet>
        <title>Policies | Contranorm Media & Production</title>
        <meta name="description" content="If you haven't done so yet, check out Contranorm Media & Production's Terms & Conditions." />
        <meta name="keywords" content="ownership policy, mission statement, vision statement, terms & conditions, code of conduct, privacy policy, support policy, termination policy, copyright statement, DCMA notice" />
        <link rel="canonical" href="https://contranormdigital.com/articles" />
      </Helmet>
      <Header className="header"  />
      <div className="stripe"></div>
      <div className={`sidebar ${showText ? 'expanded' : 'collapsed'}`}>
        <SideBar  
        onNavClick={scrollToSection} 
        filterCondition={filterCondition} 
        className="sidebar" 
        collapsed={collapsed}
        onToggleSidebar={handleToggleSidebar}  />

      </div>
      <div className={`content-container ${collapsed ? '' : 'expanded'}`}>
        <div id="Mission">
          <h4 ><strong>Our Commitment to You</strong></h4>
          <AudioSection 
              audioUrl={visionUrl}  
              onPlay={handlePlayAudio} 
              isPlaying={audioPlaying === visionUrl}/>  
            <p>At Contranorm Media & Production, we're driven by redefining what's possible. Our mission is to offer innovative solutions that align with your goals and equip you for success. 
            </p>
        </div>
        <div id="Vision">
          <h4></h4>
            <p> Empowering you to achieve greater success means delivering innovative, high-quality digital goods and support services that align with your goals and create their own demand. That's why we’re dedicated to discovering, creating, and implementing transformative strategies that meet your unique needs. Whether you’re a creative, a non-traditional student, or an emerging professional, we’re here to help you navigate diverse professional landscapes with confidence. From tackling challenges like housing, employment, and scholastic achievement to pursuing entrepreneurship and self-expression, we’re your partners in progress. We offer digital goods from 3-D animations to creative design, e-books, and ghostwriting services. Our advocates provide personalized support and document preparation services from college essays to federal grant-writing for academics and professionals alike.  Together, let’s shift the odds in your favor and turn possibilities into realities!</p>
        </div>
        <div id="Ownership">
          <h4><strong>Ownership</strong></h4>
          <AudioSection 
              audioUrl={ownershipUrl}  
              onPlay={handlePlayAudio} 
              isPlaying={audioPlaying === ownershipUrl}/>  
            <p>By purchasing, using, accepting, accessing, and/or requesting our Deliverable(s), you agree to take full responsibility for the use and/or outcome of such use. When an Independent Creator or collaborator, including Ghostwriters, provides services for us or on your behalf, we and our collaborators act as a conduit for information controlled by you. In such cases, excluding information protected by copyright, you are the owner of this data and are responsible for the content transmitted across our network and/or third-party networks (e.g., images, written content, graphics, etc.).

            Except as expressly provided in Contranorm Media & Production's Terms and Conditions, we do not claim ownership over any Deliverable made accessible and/or sold to you. You own the intellectual property rights to any Deliverable created directly by us that you have paid for, received, or accepted. However, if a Deliverable is created by a third-party creator, the intellectual property rights remain with the third party, unless otherwise specified in a separate agreement. You are granted a license to use the Deliverable for your personal or commercial purposes. No part of any Deliverable(s) provided by us or made available to you may be copied, reproduced, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, or submitted, in any way, to any other person, entity, computer, server, website, or medium for publication, submission, or distribution under the authorship and/or ownership of any creator(s), ghostwriter(s), designer(s), affiliate(s), associates, or subsidiary of Contranorm Media & Production.
            </p>  
        </div>
        <div className="Terms" id="Terms & Conditions">
          <h4><strong>TERMS & CONDITIONS</strong></h4>
          <AudioSection 
              audioUrl={definitionUrl}  
              onPlay={handlePlayAudio} 
              isPlaying={audioPlaying === definitionUrl}/> 
          <div>
            <p>
              These Terms & Conditions constitute a legally binding agreement between "you" and Contranorm Media & Production (“CMP”, “we”, “our” or “us”). The Terms & Conditions govern your use of the digital goods and/or services made available to you on or through our website(s) or otherwise.
            </p> 
            <br />
            <p>“Client,” “You,” and/or “Your “refers to you and/or any physical person or legal entity that you represent, or are represented by and/or that you make a payment for.
        
            “Us,” “We,” and/or “Our” refers to Contranorm Media & Production, its ghostwriters, independent creators, affiliates, associates, officers, owners, directors, employees, agents, and subsidiaries.
            </p>
             <br />
            <div className="centeredItemStyle">
            <u> Deliverable(s):</u>  Defined as any order, project, digital product, digital good, digital asset, feature(s), service(s), and/or information paid for by you in order for said goods or services to be delivered, provided, offered, and /or made available to you.
            <br /><br />
            
            <u>Digital Asset(s):</u>Defined as a digital good or any content or data that exists in digital form and has value. This includes, but is not limited to, images, videos, audio recordings, documents, software, applications, source code, and any other form of digital content. Digital assets also encompass cryptocurrencies, tokens, non-fungible tokens (NFTs), encrypted protocols, blockchain data, and distributed ledger technologies (DLTs).
            <br /><br />

            
            <u>Relevant Feedback:</u> Defined as any commentary, provided by the client, that identifies factual inaccuracies, errors, and/or omissions that must be acknowledged and corrected before an order is finalized.
            <br /><br />
            
            <u>Timely Reply:</u>  Defined as taking action prior to an established deadline by providing the files or data, a comprehensive response, complete instructions, relevant feedback, payment, and/or additional information requested. 
            <br /><br />

            <u>Interactive Session: </u> Defined as any communication by email, chat, audio messaging, and/or video with the Support Desk or IT. It begins when Contranorm Media & Production renders a response to a client's inquiry, message, or interaction. The session ends at the close of the same calendar day (11:59 PM EST), regardless of whether the client replies or continues the conversation.

            </div>
            <br />
          
            <p>
            If the client does not reply on the same calendar day but responds or interacts with the Support Desk or IT the following day, a new interactive session begins the moment Contranorm renders another response. Each interactive session is counted separately, even if the conversation pertains to the same topic or issue.

            </p>

            
          </div>
        </div>   
        <div className="Risk-Warning"> 
          <h4><strong>I. Risk Warning</strong></h4>
          <AudioSection 
            audioUrl={riskUrl}  
            onPlay={handlePlayAudio} 
            isPlaying={audioPlaying === riskUrl}/>  
          <p> 
            If you do not understand and accept these Terms & Conditions in their entirety, you should not register for an account on our website(s) or access or use any application, user interface, or service that we offer. 
                  
            We are not your legal, academic, professional, or financial intermediary, agent or advisor, and we have no fiduciary relationship or obligation to you in connection with any Transactions or other activities you undertake when using our products, services, website(s), or application(s). We do not provide legal, medical, financial, academic, or consulting advice of any kind, and no communication or information that we provide to you is intended as, or should be construed as, advice of any kind. Any documentation provided by us to you is for informational and/or educational purposes only. 
              
            By engaging with our online content, using our service(s), website(s), digital asset(s), or product(s), you acknowledge and consent that any information you provide, including personal data and files, may be stored on the permaweb and/or through blockchain technology. This decentralized data storage method ensures online permanence and immutability, meaning that once the information is recorded, it cannot be modified or deleted. While these technologies offer enhanced security, permanent storage, and transparency, using our website(s) and/or any digital resource, good or service that we provide exposes you to certain risks:
          </p>
                <br /><br />
          <p  className="centeredItemStyle">
            <u>Permanent Record</u> The legal and regulatory environment governing blockchain and permaweb technologies is continuously evolving. Changes in laws or regulations may impact how your data is used, shared, or protected. Data submitted to the permaweb before such regulatory changes are enacted cannot be altered or removed. Consequently, it is essential to understand that any information shared may be permanently stored on a decentralized blockchain-supported database and will be subject to the regulatory framework in place at the time of submission. Information stored on the permaweb or blockchain is publicly accessible and cannot be altered or removed. This permanence is indefinite. Any data that you share may be stored on the permaweb, at any time, and may be used in conjunction with blockchain technology, used to generate NFTs, official transcripts, self-validating awards, affidavits, and/or immutable documents for legitimate business purposes.
                        <br /><br />
            <u>Privacy Concerns</u>By submitting data and engaging with our digital assets, goods, and services, you accept the inherent risks associated with permaweb and blockchain technologies, you agree to indemnify and hold harmless Contranorm Media & Production, its affiliates, independent creators, employees, contributors, agents, and partners from any and all claims, damages, losses, or liabilities arising from your use of our services or submission of data. You further acknowledge and agree that by submitting data and engaging with our digital assets, goods, and services, you waive any reasonable expectation of privacy, control, or modification of your data once it is stored on the permaweb or using blockchain technology. While blockchain technology provides a high level of security, it does not guarantee complete anonymity. Information may be traceable back to you, potentially compromising your privacy.
                        <br /><br />
            <u>Data Breach Risk</u> Although blockchain and permaweb technologies are designed to be secure, no system is entirely immune to hacking or data breaches. In the event of a security breach, your data may be exposed.
        
                      
                        <br /><br />
            <u>Financial</u> By posting, uploading, or offering your product on our website(s) for trade, use, or sale, you may incur financial losses due to low sales, limited engagement, or insufficient demand. Additionally, initiating a project, leveraging our platform, or requesting a service may result in financial loss if the outcome does not align with your expectations or desired results.
                        <br /><br />
            <u>Web 3</u>Connecting your crypto wallet through Web 3 interfaces exposes you to potential risks. These risks include unauthorized access to your wallet, phishing attacks, or malicious smart contracts that may compromise your funds or sensitive data. Additionally, engaging with decentralized applications (dApps) carries the risk of interacting with unaudited or poorly coded contracts, which could lead to loss of assets. It is your responsibility to ensure the security of your wallet, verify the authenticity of dApps, and exercise caution when granting permissions or signing transactions.
                        <br /><br />
            <u>Operational</u> Using our website(s) exposes you to operational risks. These risks include potential losses resulting from malfunctions of the website(s), interface, or application, as well as offerings or digital assets. Such issues may arise from system-wide failures, payment processing errors, hacker attacks, or other technical malfunctions.
                        <br /><br />
            <u>Regulatory</u> Engaging with our content, utilizing our services, or appointing a member of our support team as your designated representative, advocate, educator, creative, ghostwriter, or professional assistant involves regulatory risk. This risk arises from potential limitations on the scope, level of involvement, or extent of actions we may take in support of your cause. Such limitations could result in a loss or unmet expectations due to compliance with laws, industry regulations, or internal policies.
          </p>
          <br /><br />
          <p>
            <strong> If you do not agree with these Terms & Conditions or are uncomfortable with the potential risks, do not request, use, or interact with any Contranorm Media & Production website, application, digital good/asset, or service. Your use of our website(s), service(s), and/or product(s) constitutes your acceptance of all associated risk and to be bound by these Terms & Conditions, without limitation, qualification or change upon the publication or posted changes to these Terms and Conditions.</strong>
          </p>

        </div>
        <div>
          <h4><strong>II. Sign Up / Login</strong></h4>
          <AudioSection 
              audioUrl={loginUrl}  
              onPlay={handlePlayAudio} 
              isPlaying={audioPlaying === loginUrl}/>  
          <p> 
            2.1 You agree not to impersonate any other individual or entity or attempt to interfere with any good(s), service(s)technology, and/or website(s) provided, owned, managed, and/or operated by us  to pursue fraudulent, illegal, or unlawful activities. You may not solicit or use any of our good(s), service(s),  and/or technologies, for any purpose that is prohibited by law or to infringe upon the rights of Contranorm Media & Production or others. 
            <br /><br />
           
            
            2.2 It is your responsibility to provide accurate, complete, and up-to-date information, including your full legal name and any other details requested when interacting with the user interface.  Failure to provide accurate information may result in permanent, unalterable errors on Deliverables, services, and other related outputs, including faulty documentation that may result in unfavorable or unforeseeable consequences.
            <br /><br />
            2.3 By registering for an account, accessing the user interface through our website(s) and/or using any service that we offer, you agree that you have read, understood and accepted these Terms & Conditions, together with any additional documents or terms referred to in these Terms & Conditions. 
            <br /><br />
            2.4  You acknowledge and agree that you will be bound by and will comply with these Terms & Conditions, as updated and/or amended.
             <br /><br />
          </p>
        </div> 
        <div> 
          <h4><strong>III. Payments & Refunds</strong></h4>
            <AudioSection 
                audioUrl={refundUrl}  
                onPlay={handlePlayAudio} 
                isPlaying={audioPlaying === refundUrl}/>
            <p> 
              3.1  By accepting our Terms and Conditions through the purchase of and/or use of our digital goods/services, you agree to take full responsibility for the outcome arising out of our policy, policies, written agreements between us and you and/or any contractual agreements between us and you.
              <br /><br />
              3.2  You agree that you will not take on any action that imposes an undue, unreasonable or disproportionately large load on us or our systems. This includes but is not limited to the misuse of any feature or service that we provide or the improper treatment of our staff. You agree that you will not overburden our systems or staff with friviolous, unnecessary, irregular, inaccurate, irrelevant, or superfluous documentation, data, tasks, or requests.
              <br /><br />
              3.3 Contranorm Media & Production will not be liable for any damages, of any kind, arising from its contractual agreements between us and you, our policies which may affect you, or the use of any services or digital goods offered, sold, provided, or otherwise made available to you, including, but not limited to direct, indirect, incidental, punitive, and consequential damages.
              <br /><br />
              3.4 Contranorm Media & Production expressly disclaims any and all representations and warranties, whether expressed or implied, that its website(s), Deliverable(s), publication(s), application(s), digital asset(s) and/or service(s) will meet your requirement(s) or expectation(s), will always be available or timely or will become accessible, function, and/or operate without error. 
              <br /><br />
              3.5 All payments are to be made through the third-party payment processor enabled on our website(s) in dollars or its (USD) equivalent at the time of purchase/payment. When connecting your web 3 wallet, you agree to initiate payment with $USDC, $USDT, $BTC, OR $ETH. Upon confirming your payment using your web 3 wallet, you acknowledge and accept that Contranorm Media & Production will not be responsible for any failed transaction, transaction fees, or payments issued to the wrong digital wallet. You also agree and accept that you are not entitled to a refund in such case, or if you fail to make your payment in the proper currency. You are strongly discouraged from attempting to make a payment outside of our user interface, as payments made outside of our platform may not credit to your account and are not eligible for a refund.
              <br /><br />
              3.6 Do not place an order if you experience any technical difficulty. We expressly disclaim any liability for damages, including those arising from a faulty device, improper use of or access to our application or website(s) to include any technical issues resulting from your misuse or improper use of any online resource or digital asset that we provide. We are not responsible for any losses you incur related to these factors. 
              <br /><br />
              3.7 It is your responsibility to determine whether any Deliverable, information or service that we offer or provide is appropriate for you according to your personal, financial, or professional objectives, legal circumstances and/or academic standing. You are responsible for any associated loss or liability. Before making the decision to request, use, or purchase any Deliverable, service or digital asset, you should conduct your own due diligence. We are not responsible for the decisions you make to request or purchase any product or service that we offer. We are not responsible for the purchase, disposal, acquisition, or storage of any digital asset made available to you based on any information or service provided by you or us, including any losses you incur arising from your decision to engage with said asset.
          
                <br /><br />
              3.8 All orders must be placed through the user interface.  Payment processor fees (6%) are included in all prices published. All projects are pre-paid, and any funds requested  by us are to be paid in full before any Deliverable will be made available to you.  Any charges that you incur will be consistent with the fees established by the Price Calculator.  In the event that you confirm payment or submit a payment for a New Project, digital asset or Deliverable or in the event that you pay for a premium service, you authorize us to charge you for any associated charges.
                <br /><br />
              3.9 You agree to pay the exact amount quoted by the online Price Calculator. Failure to pay the exact amount quoted, to include failure to adhere to Contranorm Media & Production's Terms and Conditions, in addition to any prerequisite placed on a Deliverable, failure to provide a timely reply, failure to provide relevant feedback, and/or failure to follow instructions made known to you, in writing, regarding any order, written agreement, SPECIAL OFFER, invoice, and/or Deliverable will forfeit any rights or claim(s) that you may have to a refund. 
               <br /><br />
              3.10 When insufficient funds have been received on any invoice and/or the payment of any particular Deliverable, we reserve the right to request that the unpaid balance be paid in full, refuse an order, cancel an order, and/or refund any monies paid (minus payment processor fees and administrative charges) with or without notice. Additional requests for Deliverable(s), digital goods, and/or services will not be processed until any unpaid balance is settled in full. 
                <br /><br />
              
              3.11 Failure to adhere the Terms and Conditions of any written agreement, SPECIAL OFFER, to include timely payments, may result in the withdrawal of the aforementioned SPECIAL OFFER, reverting any and all discounted rates on any and all unpaid Deliverables to each Deliverable’s standard price(s) and rate(s). Any client who does not adhere to the terms, agreements, conditions, and stipulations set forth under a SPECIAL OFFER may be denied services under the SPECIAL OFFER, subjecting the client to pay standard rates and prices. 
                <br /><br />
              3.12 Failure to make timely payments in accordance with a Payment Plan shall be subject to a late penalty fee of fifteen dollars, administrative fees of five percent, financial recovery  fees of eight percent and any other additional fees, to include the payment processor fees of six percent.  These additional charges shall be applied in addition to the minimum payment every fifteen days that the minimum payment remains late and unsettled on an outstanding balance. 
                <br /><br />
              3.13 Overpayment, to include any unused funds will not be applied toward any future Deliverable as a credit. Any price published and/or quoted to you applies to open, pending, or future orders and/or offers. An order opens upon proof of payment and closes upon its established deadline or within twenty-four (24) hours of the Deliverable having been made available to you, whichever sooner. If the Deliverable requires complete instructions, and/or additional documentation/files to produce or process before its established deadline, it is your responsibility to provide said data in a timely manner.
                <br /><br />
              3.14 For all project types, you agree to provide all relevant order details requested of you, to include complete instructions and supporting documentation before confirming your order submission. 
                <br /><br />
              3.15 We disclaim any responsibility for favorable outcomes resulting from targeted investigations. Additionally, we disclaim that any information can be accessed through targeted investigations on publicly accessible government databases, websites, or other online sources. We also disclaim the accuracy or reliability of any information obtained by us through the process of Data, Research, and Retrieval (DR&R). The information gathered during these processes may be incomplete, inaccurate, or outdated, and is subject to the limitations of available data. 
                <br /><br />
              3.16 Contranorm Media & Production will provide notice of any charges or extra charges before you enter a premium service area.  You are responsible for any charges for premium content incurred through your account.  We are not liable for any loss that you may incur as a result of someone else using your account, whether with or without your knowledge.
                <br /><br />
              3.17 You may cancel your subscription at any time, by contacting us, in writing, through the venue identified with the subscription service or by submitting a support ticket. In the event that you have paid a fee or elected a premium service and/or premium service packaged plan and you cancel or are no longer interested in a premium service before the end of your membership period, or before you have accessed the premium service, we will not return any portion of any fee that you have paid.
                <br /><br />
              3.18 We reserve the right to cancel your access to any premium service, subscription, and/or membership, at any time, for any reason, with or without notice.  If we cancel your membership, subscription, or terminate access to a premium service or any portion thereof, upon such termination, we may return the fee associated with the unused portion. Any fee reversal will be made available  via Paypal within 90 days of the termination, unless you are granted access to said membership, subscription, and/or premium service within that period. <br /><br />
           
              3.19 Any refund issued will account for payment processor fees and administrative charges. Administrative fees will be calculated as the greater of fifteen (15) dollars or 5% of the total order value. These nominal fees are intended to promote the efficiency of operations, ensure sustainability, deter improper engagement and/or the misuse of our resources, and to cover the expense(s) incurred by Contranorm Media & Production in the preparation of, processing, management, and/or termination of any service, Deliverable, or action associated with your account and/or order. Additional costs and restrictions may apply on all orders—open, pending, or closed.
                <br /><br />
              3.20 Administrative charges, penalties, payment processor fees, and financial recovery costs, where applicable, will be deducted before issuing any refund.
                <br /><br /> 
             
              3.21 Contranorm Media & Production reserves the right to refuse, cancel, or refund any order at any time, with or without prior notice. 
                <br /><br />
              3.22 Refunds will not be issued if doing so would result in a negative balance or deficit. 
                <br /><br /> 
          </p>
        </div> 
        <div>
          <h4><strong>IV. Order Status</strong></h4>
            <AudioSection 
              audioUrl={orderStatusUrl}  
              onPlay={handlePlayAudio} 
              isPlaying={audioPlaying === orderStatusUrl}/> 
            <p> 
              4.1 It is your responsibility to access your dashboard through the user interface to monitor the progress of any project that you initiate. 
              <br /><br />
              4.2 It is your responsibility to  familiarize yourself with any prerequisites, regulations, or requirements associated with your project prior to the project deadline. 
              <br /><br />
              4.3 Contranorm Media & Production requires complete and accurate instructions, responses, and/or documentation to process any Deliverable. Failure to provide complete, thorough, and accurate information, as requested, in a timely manner, and before the established deadline(s), may result in a denial of services or canceled order with or without notice.
              <br /><br />
              4.4 Relevant feedback, on any Deliverable(s), is limited to observations identifying factual inaccuracies, omissions, and/or errors. Any request to modify completed Deliverable(s) outside of these limitations may be subject to additional charges.
              <br /><br />
              4.5 All orders, unless same-day delivery projects, and/or otherwise stipulated, close within twenty-four (24) hours of the project deadline. 
              <br /><br />
              4.6 Any order processed under a same-day delivery request closes once the Deliverable(s) has been made available to you, unless an extension is requested and/or relevant feedback is provided by the Client upon receipt, necessitating Contranorm Media & Production additional time to finalize the order. 
              <br /><br />
            </p> 
           
          <h4><strong>V. Timestamps</strong></h4>
            <p>
              All timestamps are made visible via electronic mediums. All deadlines are based on the Eastern Standard Time Zone (EST). Additional time to revise a Deliverable may be requested by us or you before an open order closes. 
            </p>
        </div>
        <div>
          <h4><strong>VI. Time Extensions</strong></h4>
          <AudioSection 
              audioUrl={timestampUrl}  
              onPlay={handlePlayAudio} 
              isPlaying={audioPlaying === timestampUrl}/>  
            <p>
              6.1 Contranorm Media & Production retains the right to deny requests for extensions of additional time beyond the specified time limits or deadlines made manifest in these Terms & Conditions, its written agreements, confirmation email, SPECIAL OFFER(S), and/or conditions associated with any Deliverable(s).  With regard to Deliverable(s), we reserve the right to extend a deadline so that an order remains open; however, the additional time awarded may not exceed forty-eight (48) hours or two (2) additional calendar days on any Deliverable costing $350 or less. 
              <br /><br />
              6.2 All deadlines on orders exceeding $350 may be applied upon request and may be subject to additional terms and conditions.  
              <br /><br />
              6.3 Any modification, change, addition, omission, adjustment, and/or correction requested after an order has closed  will be processed as a new order. Expect to pay the fee associated with each new request. 
              <br /><br />
              
              
            </p>
        </div>  
        <div>
          <h4><strong> VII. Project Completion & Delivery</strong></h4>
          <AudioSection 
              audioUrl={projectCompletionUrl}  
              onPlay={handlePlayAudio} 
              isPlaying={audioPlaying === projectCompletionUrl}/>  
          <p>
            7.1 Upon the successful submission of a "New Project" through the user interface, the registered client will receive a confirmation email containing the order details, instructions, and the actions they must take regarding their Deliverable before the project deadline.
            <br /><br />
            7.2 The Deliverable will be ready to view and download on or before the specified deadline. 
            <br /><br />
            7.3 Feedback must be provided within twenty-four (24) hours of the established deadline by logging into the website. After a successful login, clients will be redirected to their dashboard, where feedback can be saved and submitted using the "Sticky Note" feature. 
            <br /><br />
            7.4 Documentation will be delivered as a digital asset in PDF format, unless otherwise specified, and other paid file types will be available for download through the user interface, as outlined in the confirmation email.
            <br /><br />
            7.5 All orders close, unless otherwise specified, within twenty-four (24) hours of the project deadline. We do not guarantee the availability of a Deliverable after an order has closed. If you have purchased a digital asset, it is your responsibility to download and/or access the file from the user interface before the Deliverable becomes inaccessible.
          </p>
        </div>   
        <div>
          <h4><strong> VIII. Subscription Service</strong></h4>
          <AudioSection 
              audioUrl={subscriptionUrl}  
              onPlay={handlePlayAudio} 
              isPlaying={audioPlaying === subscriptionUrl}/>  
          <p> 
            8.1 By initiating payment for a Contranorm Media & Production Subscription Service, you agree to the charges and rules outlined in the service description, which are enforceable under this clause even if not explicitly stated in these Terms & Conditions. 
              <br /><br />
            8.2 Subscriptions automatically renew and incur periodic charges—bi-weekly, monthly, quarterly, or annually—until canceled. 
              <br /><br />
            8.3 Basic and Standard Support Service subscriptions are billed annually upfront. Premium Support Service subscriptions are pay-as-you-go, with the initial fee covering a minimum expected usage of 10% of the monthly fee.  The maximum monthly fee for Premium Support Service is $999.00. The minimum sessions at the 10% minimum usage is 0-2 sessions per month, whichever is less.  Maximum usage is defined as either: 3 interactive sessions (emails, phone calls, live chats, or live audio messages, in any combination) or a combined total of 20 interactive sessions, whichever limit is reached first.<br />
              <br /><br />
            8.4  An interactive session begins as soon as a response is rendered by Contranorm Media & Production and ends at midnight on the same calendar day. If a client interacts with IT or the Support Desk and Contranorm responds, the session is considered active. If the client replies the next day, and is provided a response by Contranorm Media & Production, that response initiates a new session, regardless of whether it is a continuation of the previous topic. Similarly, if we respond again the next day, it counts as a new session. Multiple interactions to include any combination of chats, audio messages, phone calls, and emails --regardless of their duration-- within the same calendar day, count as one session. 
            <br /><br />
            8.5 We reserve the right to refrain from any and all communication through the Premium Support tier if a client has exceeded the initial 10% limit, until the maximum support usage is paid at the tier's full price of $999.00 or the client elects a new Support Services level.
          </p>
        </div> 
        <div>
          <h4><strong> IX. Third-Party Purchases</strong></h4>
          <AudioSection 
              audioUrl={thirdPartyUrl}  
              onPlay={handlePlayAudio} 
              isPlaying={audioPlaying === thirdPartyUrl}/> 
          <p>
            9.1 Our website(s) may enable you to download, order and receive products, information, services, and/or digital assets from a person or business that is not controlled, owned, or managed by Contranorm Media & Production.  The purchase, payment, warranty,  delivery, maintenance, and all other matters concerning any transaction with such individual(s) and/or business is solely between you and said entity.  
              <br /><br />
            9.2 Contranorm Media & Production does not endorse, warrant, or guarantee third-party products, services, information, or digital assets, and is not liable for the accuracy, completeness, or usefulness, availability of such products, assets, or services.  Contranorm Media & Production will not be a party to or in any way be responsible for monitoring any transaction between you and any third-party provider(s) or for ensuring the confidentiality of any information that you provide to any third party.  Any separate charges or obligations that you may incur in your dealings with these third parties are your responsibility and are not part of any fee, if any, that is charged by Contranorm Media & Production.
              <br /><br />
             
          </p>
        </div>   
        <div>  
          <h4><strong>X. Intellectual Property Rights</strong></h4>
          <AudioSection 
              audioUrl={propertyRightsUrl}  
              onPlay={handlePlayAudio} 
              isPlaying={audioPlaying === propertyRightsUrl}/>  
          <p>
            Except as expressly provided in these Terms and Conditions, Contranorm Media & Production does not claim ownership over any Deliverable made accessible and/or sold to you. This means that you own the intellectual property rights of any Deliverable that you have paid for and received or accepted that does not belong to a third-party. No part of any Deliverable(s) provided to you may be copied, reproduced, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, or submitted, in any way, to any other person, entity, computer, server, website, or medium for publication, submission, or distribution under the authorship and/or ownership of any creator(s), ghostwriter(s), designer(s), affiliate(s), associates, or subsidiary of Contranorm Media & Production.
          </p> 
        </div>  
        <div>
          <h4><strong>XI. Intellectual Property Waiver</strong></h4>
          <p> 
            11.1 By emailing, submitting, transmitting, posting, uploading, modifying, interacting with our website(s), application(s), or otherwise providing any data, information, digital asset(s), and/or material to Contranorm Media & Production, through its online resource(s), user interface, website(s) or by direct email to any url owned, managed, and/or operated by Contranorm Media & Production, whether solicited or unsolicited, you are granting Contranorm Media & Production and its designees a royalty-free, fully paid, non-exclusive, irrevocable, perpetual, unrestricted, worldwide license to reproduce, publish, transmit, perform, display, sublicense, create derivative works from and otherwise use for any purpose, including, without limitation, advertising, and promotional purposes, alone or as a part of other works, in any form, media, or technology now or thereafter known.  
                <br /><br />
            11.2 No credit, approval or compensation is due to you for your online engagement with our digital resource(s) and any such use of material, information, data, and/or digital asset(s) that you may submit.
                <br /><br />
            11.3 Any non-personally identifiable material, digital asset(s), information, and/or data that is acquired from you through your engagement with our online resources, website(s) and/or digital assets is and will be treated as non-proprietary and non-confidential as to you, unless specifically stated in our Privacy Statement.
          </p>
        </div>
        
        <div>
          <h4><strong>XII. Disputes</strong></h4>
          <AudioSection 
              audioUrl={disputesUrl}  
              onPlay={handlePlayAudio} 
              isPlaying={audioPlaying === disputesUrl}/>  
            <p>
              12.1 Your utilization of any good(s), service(s), to include any application(s) or website(s) provided, made available, or managed by Contranorm Media & Production is subject to arbitration, necessitating the resolution of all valid disputes through binding arbitration.  If a dispute arises between us and you, you agree to file a "Demand for Arbitration" within seventy-two (72) hours that the cause of action occurred. You may submit this request directly to support@contranormdigital.com with the subject line being "Demand for Arbitration." In your notice, you must identify the "Cause of Action", in narrative form, by describing the underlying reason for the claim. The notice must be physically signed and dated at the time of transmission. You agree that a "Demand for Arbitration" resulting from a cause of action that is filed after the 72-hour period is barred, and any legal relief sought through arbitration, to include arbitration itself, pursued in connection with said cause of action shall be constituted as invalid, unenforceable, frivolous and/or improper. 
              <br /><br />
              12.2 Arbitration shall be conducted by a panel of three arbitrators, two of whom shall be either: (a) a retired judge, (b) an attorney licensed to practice law in the district or city in which the arbitration is convened, (c) a retired or active member of the military, (d) a US federal law enforcement officer, or (e) a duly credentialed paralegal residing in the country in which he or she has been authorized to operate. The third arbitrator must possess specific knowledge or expertise relevant to the dispute. The environment, whether virtual or physical, in which the arbitration panel will convene must be mutually agreed upon by all parties, with the arbitration costs, if any, to be borne by the party not prevailing in the case. The arbitrators' decision shall be final and binding. The arbitration panel shall have the power to award, and the parties may seek any type of non-pecuniary relief that would be available under applicable law in which the client resides, so long as the claim is not held by the arbitration panel as friviolous or improper.  
              <br /><br />
              12.3 You acknowledge and agree to arbitrate all disputes that may arise with us, and in doing so, you irrevocably waive your right to litigate your claim in any court or have a jury trial.  Discovery and appeal rights may be limited in arbitration; however, you knowingly accept these risks by using, accessing, and/or soliciting the good(s), service(s), application(s), and/or website(s) of Contranorm Media & Production. 
              <br /><br />
              12.4  By using, soliciting, and/or accessing our services and/or digital goods, you expressly waive the right to bring or join class actions or dispute a written agreement at that time and at any point in time in the future. 
            </p>
        </div>
        <div>
          <h4><strong>XIII. Online Services & Digital Assets</strong></h4>
          <p>
            13.1 The use of any Deliverable(s) provided to you is for your personal/professional use only. No information provided by us is intended to constitute/substitute medical or legal advice, guidance, instructions, or assistance. 
                <br /><br />
            13.2 Contranorm Media & Production does not make any representations or warranties relating to any of its Deliverables. You expressly agree that your use of the Deliverable(s) is at your sole risk. Any Deliverable(s) is/are provided “as is” and “as available” for your use, without warranties of any kind, either expressed or implied, unless such warranties are legally incapable of exclusion. 
                <br /><br />
            13.3 When requesting or using our good(s) and/or service(s) it is your responsibility to familiarize yourself with the associated regulations, risks, and potential outcome before you submit your payment or engage with said product and/or service. 
                <br /><br />
            13.4 Contranorm Media & Production may cancel an order at any time, refuse to process a request and/or deny access and/or preparation of any digital asset or online service for any reason, at any time. 
          </p>
        </div>
        <div>
          <h4><strong>XIV. Indemnity</strong></h4>
          <AudioSection 
              audioUrl={indemnificationUrl}  
              onPlay={handlePlayAudio} 
              isPlaying={audioPlaying === indemnificationUrl}/>  
          <p>
            14.1 Additional terms and conditions may apply to any Deliverable(s), and written agreements—all of which are made part of these Terms and Conditions by this reference. You agree to abide by such other Terms and Conditions, including where applicable, representing that you are of sufficient legal age to use, solicit, purchase, or participate in the development, creation, or production of such Deliverable(s). <br /><br />
            14.2 We may make changes to any policy, procedure, and/or agreement at any time, without notice. These changes are effective immediately upon the posting or publication of such changes.
            <br /><br />
            14.3 You agree to indemnify and absolve Contranorm Media & Production, including its ghostwriters, independent creators, affiliates, associates, creatives, designers, officers, owners, directors, employees, agents, and subsidiaries, from any and all claims, unresolved disputes, demands, suits, proceedings, liabilities, judgments, losses, damages, expenses, and costs assessed, associated with, or incurred by Contranorm Media & Production directly or indirectly, with respect to or arising out of (i) your failure to comply with these Terms and Conditions (ii) Your breach of Your obligations under these Terms and Conditions; (iii) Your breach of your obligations under any written agreement; (iv) your use of the rights granted hereunder, including without limitation any claims made by any third parties; (v) your violation of any third party right or applicable law, including without limitation any intellectual property, publicity, or privacy, right or law, and/or; (vi) any claim that any action taken by us and/or any Deliverable(s) or any aspect that our action(s) and/or Deliverable(s) may cause, has caused, or may have caused to you and/or a third party.
            <br /><br />
            14.4 You irrevocably and unconditionally agree to release us from any and all claims, demands, or liabilities (and waive any rights you may have against us, now or in the future, in relation to any losses you may suffer or incur), arising directly or indirectly out of or in connection with any dispute you have with any other client of Contranorm Media & Production or any third party, related to our services, including any digital asset or transaction(s), or the subject matter of these Terms & Conditions.  
          </p>
        </div>
        <div id="Code of Conduct">
          <h4><strong>XV. Code of Conduct</strong></h4>
          <AudioSection 
              audioUrl={conductUrl}  
              onPlay={handlePlayAudio} 
              isPlaying={audioPlaying === conductUrl}/>  
            <p> 
              15.1 Pursuant to Contranorm Media & Productions Terms and Conditions, which include by this reference, this Code of Conduct, you must adhere to all requirements for access and use.  Unauthorized use of any good(s), service(s) and the unauthorized entry to any portion of our website(s) is strictly prohibited.  You may not either directly or through the use of any device, software, internet site, web-based service, or other means, incorporate content on this site into any stream, nor may you retransmit any portion of any content on our website(s) via any hardware, software or application. You are strictly prohibited from making any portion of our website(s) available or visible to others via frames, screen-shot captures, video recording or in-line links. In soliciting, accessing, and/or using Contranorm Media & Production's website(s), digital good(s), and/or service(s), you fully understand, acknowledge, and agree that your consent to this Code of Conduct Agreement is final and irrevocable:
            </p>
            <p  className="centeredItemStyle">
              <br />
               
                (1) You shall not post, transmit, provide, or disclose any defamatory, libelous, slanderous, or false information about any individual or entity anywhere on our website(s). If you become aware of any prohibited content on this site, please contact IT immediately at ticket@contranormdigital.com to request its removal.
                <br /><br />
                (2) You shall not post, transmit, provide, or disclose any personally identifiable information, sensitive subject-matter, financial details, or otherwise private information about any individual or entity on our website(s). If you become aware of any such prohibited content on our site, please contact our IT team immediately at ticket@contranormdigital.com to request its removal.
                <br /><br />
                (3)  You shall not make false claims, provide inaccurate or misleading information, or intentionally withhold relevant feedback, observation(s), material(s), or data from any designated representative, authorized representative, independent creator, associate, agent, employee, ghostwriter, or staff member working for, in collaboration with, or on behalf of Contranorm Media & Production, especially when such action(s) would impede, obstruct, or prevent said individual from conducting normal business operations or fulfilling the duties that the person may have in association with an "existing project" or "new project."
                <br /><br />
                (4) You shall not contact our affiliate(s), collaborator(s), ghostwriter(s), staff, independent creator(s), board member(s), associate(s), officer(s), owner(s), director(s), employee(s), agent(s), and/or subsidiaries in writing, electronically, virtually, or in person for matters that are personal in nature, using any email, platform, or medium owned or operated by us for issues unrelated to business operations, or that can be addressed via the website(s).  This includes refraining from using a personal email or direct message via social media platforms to contact any affiliate or staff, owner, agent, associate, director, executive, employee, independent creator, or designee for any business-related issue that can be addressed through the appropriate medium on our website(s). 
                <br /><br />
            
                (5) You shall not be disingenuous,  deceitful, dishonest, or duplicitous in your interactions with us or our clients, affiliates, and third-party providers.
                <br /><br />
                (6) You shall not impersonate another person or entity, make false claims, or falsely state or otherwise misrepresent your interaction(s) or affiliation with a person or entity, or adopt a false identity or perpetuate a false narrative if the purpose of doing so is to mislead, deceive, exploit, or defraud.
                <br /><br />

                (7) You shall not deliver, transmit, provide, post, or send any unlawful postings to or through our website(s) that advocate illegal activity as defined by local, state, federal, and international law or regulation(s).
                <br /><br />
                (8) You shall not deliver, transmit, provide, post, or send [any link(s) to] material, data, or information that could be considered harmful, obscene, pornographic, indecent, lewd, violent, abusive, profane, insulting demanding, intimidating, threatening, harassing, hateful, or otherwise objectionable.
                <br /><br />
              
           
                (9) You shall not use AI, AI Agents, or bots in connection with Contranorm Media & Production's website(s) to post content, upload, submit, or provide product(s) and/or data generated by artificial intelligence in any manner that is deceptive, misleading, or that could damage, disable, overburden, impair, exploit, defraud, hack, or otherwise interfere with the normal business operations of our website(s).
                <br /><br />
              
                (10) You shall not use Contranorm Media & Production's website(s) or solicit our product(s) and/or service(s) in any manner which could damage, disable, overburden, impair, or otherwise interfere with normal business operations or our website(s). 
                <br /><br />
                
                (11) You shall not attempt to gain unauthorized access to any website(s) or other accounts, computer system, or networks connected to, managed, and/or operated by Contranorm Media & Production through hacking, password mining or any other means. 
                <br /><br />
                (12) You shall submit all orders via the designated component of the Contranorm Media & Production website(s). <br /><br />
             
            
                (13) You are entirely responsible for ensuring that the username and primary email associated with your account is accurate.
                <br /><br />
                (14) It is your full responsibility to maintain the confidentiality of your user sign-in credential(s).
                <br /><br />
                (15) You are entirely responsible for any information, data, material, and/or digital asset(s) that you share, provide, and/or submit.
               <br /><br />
            
                (16) You are entirely responsible for any charges that you incur by submitting an order, selecting a premium service, or subscribing to any plan that we offer.
                <br /><br />
                (17) You are entirely responsible for any and all activities that occur under your username, your web 3 wallet, and/or account registered to you on our website(s).
                <br /><br />
                (18) You shall notify us immediately if you believe or suspect unauthorized activity on your account.
                <br /><br />
                (19) You shall notify us immediately if you wish to purge your personally-identifiable information from our database(s) by closing your account. Please note that this request will only apply to personally-identifiable information, and we may retain data necessary for legitimate business operations, including non-personal or transactional information.
                <br /><br />
              
            
                (20) All communication regarding existing, past, and/or future orders shall be made via the appropriate support services component available within our website(s).
                <br /><br />
                (21) You shall provide complete and accurate instructions, to include any documentation, reasonably necessary, upon the submission of a "New Project" or upon request in a timely manner if an "existing project" is being processed.
                <br /><br />
              
             
                (22) If you elect to not use the online community to resolve any technical issue that you may face, it is your responsibility to refrain from using our website(s), its application(s), or any service that we provide until any and all technical difficulties arising from your use of any component(s) featured on our website(s) has been resolved and confirmed. You may submit an IT support request via ticket@contranormdigital.com, noting that an interactive session with our support team is a premium service.
              
                (23) You shall not deliver, transmit, provide, post, or send any material or link(s) that harass(es), victimize(s), degrade(s), or intimidate(s) an individual or group of individuals because of  perceived, known, or actual objection(s), prejudice(s), disagreement(s), rejection(s), and/or dislike(s) towards the individual's and/or group's:
              </p>
              
                <br />
              
                  <p style={centeredTextWrapperStyle}>
                    <p style={centeredNumeralStyle}>(a) religion</p>
                    <p style={centeredNumeralStyle}>(b) racial affiliation, categorization, and/or identity</p>
                    <p style={centeredNumeralStyle}>(c) sexual orientation, categorization, and/or identity</p>
                    <p style={centeredNumeralStyle}>(d) gender</p>
                    <p style={centeredNumeralStyle}>(e) age</p>
                    <p style={centeredNumeralStyle}>(f) disability</p>
                  </p>
                  <br /><br />
            
            
              
        
        </div>
        <div id="Privacy">
          <h4><strong>XVI. Privacy</strong></h4>
          <AudioSection 
              audioUrl={privacyUrl}  
              onPlay={handlePlayAudio} 
              isPlaying={audioPlaying === privacyUrl}/>  
         
            <p>
            16.1 Contranorm Media & Production places a high price on building trust with our clients. To earn and uphold this trust, we pledge to communicate transparently, ensure security, and protect the privacy of personally identifiable data on our systems. Our website(s) and services are not intended for, nor designed to attract individuals under the age of eighteen. We do not knowingly collect or share personal information from any person under the age of eighteen. If we become aware that we have personal information of an individual under eighteen, we will delete that information.
              <br /><br />
              16.2 We may request and collect personal details such as your name, address, phone number, and email address when you visit our offices, register for or attend events sponsored by us or other events where Contranorm Media & Production (and/or its representative, affiliate, and/or collaborator) participates. 
              <br /><br />
              16.3 We may request, require,  and store personal details when you fill out web forms on our Website(s), including when you sign up for and agree to receive email communications from us. You may be asked to provide personal information if you use interactive features of the Website(s), such as participating in surveys, promotions, requesting customer support, submitting feedback, or otherwise communicating with us. 
              
              <br /><br />
              16.4 When registering for an account, we collect contact details, which may include your full legal name, your primary email address or the email address(es) of your account administrator(s), phone number, and addresses required for processing payment and/or administrative processes. We also may collect information about how you configure your account and track your interaction with features, elements, and/or components of our Website(s). This information, referred to as “User Information & Analytics,” is essential for continued access to your account and any service(s) that we provide, may be contingent on processing that information. 
              <br /> <br />
              16.5 By providing your personal information, you confirm that you own or have consent to provide it. We also may log Administrative User activities (e.g., actions taken to configure settings on behalf of a client or for a client's account).
              <br /><br />
              16.6 At any time, the US government may, for any lawful governmental purpose, monitor, intercept, search, and seize any communication or data transiting  or stored on our information system(s).  Any communication(s) or data stored or transiting on our website(s) may be disclosed or used for any lawful governmental purpose. 
              <br /><br />

              16.7 We are committed to keeping your personally identifiable information secure and private. We do not sell or rent your personal information. We will only share or disclose your personally-identifiable information, as reasonably necessary, to provide our services or as described in this policy, except in cases where we first provide you with notice and the opportunity to withdraw your consent. 
              <br /><br />
              16.8 If we make changes to this policy that we believe significantly affect the privacy of your personally-identifiable data, we will promptly notify you of these changes electronically by updating the policy on our website(s) and indicating the effective date of these changes. This Privacy Policy governs Contranorm Media & Production's collection, use, and disclosure of the personal information of:
            <br /><br />
            </p>
          
            <div  className="centeredItemStyle">
              
              <u>Website Visitors:</u>
              
              Individuals who visit our websites, including those who may opt to provide an email address or other contact information to receive communications from us, complete a survey, or provide feedback. For the purposes of this Policy, "Website(s)" collectively refers to www.contranormdigital.com and any other website(s) Contranorm Media & Production operates on its behalf that link to this Policy. The term "Website(s)" does not include any sites owned or operated by the Independent Creators that collaborate with Contranorm Media & Production.
              <br /><br />
            
              <u>Clients:</u>
              Individuals or entities who enter into a subscription agreement, make a one-time purchase, and/or pay for any digital good or service made availble and/or offered by Contranorm Media & Production (or its authorized partner) or individuals to whom we provide resources and/or services. "Services" refers to all digital solutions offered, marketed, and/or sold by us or our authorized partners.
              <br /><br />
              <u>Administrative Users: </u>
              
              Individuals with login credentials for a Contranormdigital.com account and/or those who administer any of the services for our client(s). In some cases, an administrative user and client may be the same person. In other instances, an administrative user may act on behalf of a client.
              <br /><br />
              <u>Authorities:</u>
                
              (i) a properly credentialed individual or legal entity who has the legal authority to access or use our client's information, or (ii) employees, agents, or authorized representatives of our customers who access or use our client's data through our website or across digital platforms.
                
            </div>
         
        </div>
        <div>
          <h4><strong>XVII. Waiver of Privacy</strong></h4>
          
            <p>
              By using our services, you acknowledge and agree that in the following circumstances, your privacy rights are and have been forfeited:
            </p>
                <br />
              <div  className="centeredItemStyle">
                <strong>Uploads, Messages & Posts</strong> When you interact with our resources, upload information to our website(s), deliver, post, or transmit your data, information, material, and/or documentation, you understand and consent to having no reasonable expectation of privacy regarding any communications or data transiting or stored on our information system(s). 
                <br /><br /> 
               <strong>Designated Representative</strong> When you elect Contranorm Media & Production, its advocate(s), staff, or assignee(s) to act as your "Designated Representative" in any administrative hearing, you agree to waive certain privacy rights. This waiver is necessary whenever we act as your Designated Representative, since we will be required to speak on your behalf, produce documentation, and/or file pleadings related to your proceedings. Our information may appear in administrative/court records in connection with your name and we may be asked to transmit, produce, and/or provide actual and factual information that may be deemed private, personal, and/or sensitive in nature. 
                <br /><br />
               <strong>Data Transfer & Transmission</strong> This condition includes the physical, electronic, and/or verbal exchange of data. When you transfer or transmit daya or an advocate, independent creator, ghostwriter, or collaborator, affilliate, third-party, or associate assigned as your Designated Representative, provides services for or on your behalf, you relinquish privacy rights regarding the content transmitted across our network and/or third-party networks. This includes but is not limited to images, written content, graphics, and information. Moreover, whenever you interact with third-party services available through or on our website(s), you are responsible for the content and/or information that you provide and the risks associated with its transmission.
                <br /><br />
               <strong> Voice & Image </strong> When participating in administrative hearings, investigations, interviews, consultations, or using interactive features of the website(s), you relinquish your privacy rights to your voice and/or image being recorded if you consent to the recording. If you do not consent, we will not record your voice or image. However, third parties, such as third-party applications, or in the event of an administrative hearing the EEOC, MSPB, or government agencies may still record the process. Any such recordings made by third parties not authorized by you to be recorded or used will not be stored on our servers or in any database managed by Contranorm Media & Production, unless you upload or provide the file for further processing or action.
               <br /><br />
               <strong> Government Intervention </strong> You acknowledge and agree that Contranorm Media & Production may be compelled by local, state, federal, or international government authorities to disclose, produce, or otherwise relinquish information, data, or materials that we have collected, have knowledge of, or have access to in compliance with applicable laws, regulations, or lawful orders. In such circumstances, you waive any expectation of privacy or confidentiality concerning the information disclosed to the extent required by law. Contranorm Media & Production shall not be liable for any disclosure made in good faith to comply with such legal obligations, including but not limited to subpoenas, court orders, or other government directives.
              </div>

        </div>
        <div>
          <h4><strong>XVIII. Confidentiality</strong></h4>
          <AudioSection 
              audioUrl={confidentialityUrl}  
              onPlay={handlePlayAudio} 
              isPlaying={audioPlaying === confidentialityUrl}/>
          <p>
            Access to confidential information is strictly limited to designated authorities, and any disclosed information is governed by our privacy policy. Personally identifiable information that is confidential in nature is restricted to the data necessary to achieve legitimate business interests. Contranorm Media & Production, it's affiliates, and/or designees uphold stringent confidentiality standards while acknowledging the inherent limitations of confidentiality when information has been made available on publicly accessible platforms.

            Our website(s) may feature blogs, community forums, comment sections, and other interactive features. Contranorm Media & Production will not share or disclose confidential, personally identifiable information about you through these mediums. Any information you choose to post or share in these public spaces is your responsibility. We strongly encourage caution when sharing sensitive details online, as posts in publicly accessible areas may be read, collected, and used by others, including data brokers employing sophisticated technologies. Such technologies, unauthorized on our website(s), may include AI bots or autonomous AI agents that scrape or scan publicly available data from the internet, including any information you have posted on other websites, online applications, or social media platforms. These methods may also include screen captures or screen-sharing.

            Given these inherent risks and limitations, Contranorm Media & Production expressly disclaims any and all responsibility for maintaining the confidentiality of information that has been disclosed on publicly accessible mediums. Contranorm Media & Production expressly disclaims any responsibility in removing, censoring, or protecting content disclosed on publicly accessible mediums, including the internet or the permaweb, whether such content has been cloned, shared, disclosed, or distributed through unauthorized means.

            In alignment with our commitment to safeguarding your personally identifiable, confidential information, CMP does not collect, store, or request your credit card or financial information. If you make a payment using our authorized third-party payment processor, the required information will depend on your chosen payment method. For example, if you pay by credit card or through a Web3 wallet, the third party will collect your card details and billing address. We do not request or have access to this financial information.

            These terms and conditions with regard to confidentiality, outlining our commitment to maintaining confidentiality within the boundaries of legitimate business needs and the inherent risks of publicly shared information.
            
            
          </p>
        </div>
        <div> 
          <h4><strong>XIX. How We Use Your Data</strong></h4>
            <AudioSection 
            audioUrl={dataUrl}  
            onPlay={handlePlayAudio} 
            isPlaying={audioPlaying === dataUrl}/>  
          <p> 
            Contranorm Media & Production processes personal information in ways that are compatible with and relevant to the purpose for which it was collected or authorized. As such, we may use your personal information to:
          </p>
          <p  className="centeredItemStyle">
            (1) Provide, operate, maintain, improve, and promote our website(s), digital goods and services for all users;
                <br /><br />
            (2) Enable you to access and use our website(s) and service(s);
                <br /><br />
            (3) Process and complete transactions, and send related information such as purchase confirmations and invoices;
                <br /><br />
            (4) Send transactional messages, including responses to your comments, questions, and requests; provide customer service and support; and send technical notices, updates, security alerts, and support and administrative messages;
                <br /><br />
            (5) Comply with legal obligations, and to investigate and prevent fraudulent activity, unauthorized access to the services, and other illegal activities;
                  <br /><br />
                  (6) We may combine information we collect as described, above, with personal information obtained from third parties. For example, we may merge information entered into our databases via an online submission form with data received from a third-party intelligence/technologies to enhance our marketing capabilities.
                <br /><br />
                
            (7) We may aggregate data acquired about our customers, administrators, and end users. For example, we may compile data to understand how web crawlers index the Internet and detect malicious activity, or to produce web traffic reports and statistics. Non-personally identifiable, aggregated data may be shared with third parties.
                  <br /><br />
            (8) We may collaborate with other individuals, companies, or legal entities to help run our business.These parties may assist in delivering customer support, processing credit card payments, managing and contacting existing clients and administrators, providing marketing support, and improving our Service(s). These Service Providers may only process personal information according to our instructions and in compliance with this Privacy Policy, the contracts we may have with them, and/or other applicable confidentiality, data protection, and security measures.
                  <br /><br />
            (9) We do not knowingly permit our Service Providers to sell any personal information we share with them or use it for their own marketing purposes or any purpose other than providing services to us.
              <br /><br />  
            <strong>In addition to sharing with Service Providers, we may re-process and/or share your information under the following circumstances:</strong>
           
          </p>
          <br />
          <p style={centeredNumeralStyle}>
            (a)  To send commercial communications according to your communication preferences, such as providing you with information about products and services, features, surveys, newsletters, offers, promotions, contests, and events from us and our partners.
          </p>
          <br />
          <p style={centeredNumeralStyle}>
            (b) To process and deliver contest, promotions or rewards;
          </p>
          <br />
          <p style={centeredNumeralStyle}>
            (c) To monitor and analyze trends, usage, and activities in connection with the Website(s) and Service(s) for marketing or advertising purposes;
          </p> 
                  <br />
          <p style={centeredNumeralStyle}>
            (d) To personalize and improve the efficiency of the Website(s), our applications, and Service(s) by providing features or content to match your objectives, interests, and preferences;
          </p>
            <br />
          <p style={centeredNumeralStyle}>
            (e) To register visitors to our office(s) and manage non-disclosure agreements, to the extent necessary for our legitimate interest in protecting our offices and confidential information against unauthorized access. 
          </p>
                <br />
          <p>
            
          </p>
          <p style={centeredNumeralStyle}>
            (f) Within the CMP Group (defined for this Policy as Contranorm Media & Production, and its subsidiaries);
          </p>
           <br />
          <p style={centeredNumeralStyle}>
            (g) With our resellers and sales partners who help distribute the Service(s) to our clients;
          </p>
          <br />
          <p style={centeredNumeralStyle}>
            (h) With an app developer, upon installation of our software from a digital Marketplace;
          </p>
            <br />
          <p style={centeredNumeralStyle}>
            (i) In the event of a merger, sale, change in control, or reorganization of all or part of our business;
          </p>
          <br />
          <p style={centeredNumeralStyle}>
            (j) When required to disclose personal information to respond to subpoenas, court orders, or legal processes, or to establish or exercise our legal rights or defend against any legal claim(s).
          </p>
          <br />
          <p style={centeredNumeralStyle}>
            (k) When necessary to investigate, prevent, or take action regarding illegal activities, suspected fraud, potential threats to the physical safety of any person, or violations of our Terms and Conditions, Self-Serve Subscription Agreement, and/or as required to comply with our legal obligations; 
          </p>
          <br />
          <p style={centeredNumeralStyle}>
            (l) Upon the legitimate request of third parties. 
          </p>
          <br />
          <p style={centeredNumeralStyle}>
            (m) To support our global operations, we may transfer and access your information worldwide, including in countries where Contranorm Media & Production operates.
          </p>
          <br />
          <p style={centeredNumeralStyle}>
            (n) For any other purposes for which you have not withdrawn your consent.
          </p>
                        <br />
          <p className="centeredItemStyle">
            (10) We may monitor, collect, store and/or process End Users' interactions with our client's internet properties and Services. This includes processing information when End Users access or use any applications, devices, endpoints, and networks that utilize our service(s) through our client. The processed information may include IP addresses, traffic routing data, system configuration details, and other data related to traffic to and from our clients' devices, applications, and/or networks.
                        <br /><br />
                      
            (11) We may collect and/or store network data, which includes models, observations, reports, analyses, statistics, databases, and other information created, compiled, analyzed, generated, or derived by the server(s), network, or traffic data generated in the course of providing our service(s). This includes information necessary to help us identify, analyze, mitigate, prevent, and block malicious activities on our network. Examples of network data include service uptime and availability metrics, request volumes, error rates, cache rates, origin and nature of malware, and IP threats.
                        <br /><br />
            (12) We will not sell your personally-identifiable information or disclose sensitive information, unless compelled to do so by the proper authority. All other data that we may have access to or may have knowledge of may be used for legitimate business purposes and to promote the efficiency, profitability, and productivity of business interests and operations.
          </p>

        </div>
        <div>
          <h4><strong>XX. Cookies & Analytics</strong></h4>
            <AudioSection 
              audioUrl={cookiesUrl}  
              onPlay={handlePlayAudio} 
              isPlaying={audioPlaying === cookiesUrl}/> 
            <p>
              When you visit our website(s), use our online resource(s), application(s), including any Community Forum that we provide, we may gather and store certain information on your device and/or in log files, which may include your Internet Protocol (IP) address(es), Google Analytics, geo-tags, system configuration details, URLs of referring pages, locale and language preference(s). We may use cookies and other tracking technologies, for analytical purposes, such as understanding how you interact with our website(s) to improve the overall client experience. You may view a complete list of cookies and adjust your cookie preferences via the “Cookie Preferences” link or “Your Privacy Choices” if these options are available in your country.   In addition to managing cookies through your browser or device settings, you may remove or block any cookies stored on your device, at any time. While cookies may be necessary in some circumstances to ensure the proper functionality of our website(s), its resources, or any application that may be used to facilitate our services on your device, periodically clearing your browser history and cookies may improve your user experience.   
            </p>
        </div>
        <div>
          <h4><strong>XXI. Rights Request</strong></h4>
            <AudioSection 
              audioUrl={rightsUrl}  
              onPlay={handlePlayAudio} 
              isPlaying={audioPlaying === rightsUrl}/> 
            <p>
              We take all reasonable steps to protect the personally-identifiable information we receive from you against accidental or unlawful destruction, loss, alteration, and unauthorized disclosure or access. We are committed to taking appropriate physical, technical, and administrative measures to safeguard and secure your personally-identifiable information, including the use of privacy-enhancing technologies like encryption.  

              If you are a resident of the European Economic Area (EEA), the UK, California, or Switzerland, please be aware that our legal grounds for collecting and using your personal data will depend on the specific details of the data and the context in which it is gathered. Typically, we will collect personal data from you only if: (a) you have not withdrawn your consent, (b) the data is necessary to fulfill a contract with you (for example, to provide the services you have requested), or (c) the processing is in our legitimate interests. Please note that if you do not provide the requested data, we may not be able to offer the requested service.

              Contranorm Media & Production acknowledges the data protection rights of individuals, and as such,  you have the right to access, correct, update, transfer, or delete your personal information from our databases as well as to restrict or object to its processing.

              Registered clients and administrative users of our website(s) can access, correct or update their account information by editing their profile or organization record at contranormdigital.com.

              You can request information about the personal information we have collected about you, including the categories of sources from which the information is collected, the business or commercial purpose for collecting or sharing it, the categories of third parties with whom we share it, and the specific pieces of personal information we have collected about you.  You can email us at support@contranormdigital.com with this "Rights Request" and we will respond within thirty (30) days. 

              Before we can complete any Rights Request that you may elect to submit, we first must verify that the identity of the person requesting the Rights Request matches the data you are requesting to exercise your rights on. If you are making a Rights Request on behalf of another person as an authorized agent, we require that either (a) the subject of the data verifies their identity by responding to any verification protocols that we initiate to confirm that they granted permission to the authorized agent, or (b) the authorized agent has power of attorney in accordance with the laws of the data subject's jurisdiction, and that the Rights Request complies with applicable privacy laws.
                  
              If a Rights Request implicates another person's data, we must balance it against the risk of violating that person's privacy rights. We will comply with Rights Requests as required by applicable law or the Swiss-U.S. or EU-U.S. Data Privacy Framework. If your Rights Request is denied, residents of Virginia and Colorado in the U.S. may appeal by responding to the denial email or by submitting a "Rights Request Appeal" to ticket@contranormdigital.com, and we will respond within sixty to ninety days. If your rights request is denied and you are not a resident of the aforementioned regions, then the appeals process is not applicable.
                  
              Any personally-identifiable information that we retain about you is done so for a period consistent with a legitimate business necessity, interest, and/or as long as necessary to fulfill and/or comply with legal obligations. The criteria we use to determine the retention period for your personally-identifiable information varies depending on several factors. Typically, we consider:
              
            </p>
            <br />
                  
                  
            <p  className="centeredItemStyle">
              (1) The purpose for initially collecting the personally-identifiable information. For instance, if you have an active account with us, we need to retain your contact information to provide support or communicate regarding your account.
              <br /><br />
              (2) The volume, nature, and sensitivity of the personally-identifiable information.
              <br /><br />
              (3) The potential risk of harm from unauthorized use or disclosure of the personally-identifiable information.
              <br /><br />
              (4) Whether we can achieve the processing purposes with less data or through alternative means. 
              <br /><br /> 
              (5) Legal requirements applicable to the data, such as statutes of limitations or contractual obligations.
              <br /><br />
              (6) When the retention period for a particular type of personally-identifiable data expires, we may delete or destroy it. If, for technical reasons, we are unable to do so, we will implement appropriate security measures to prevent further use or processing of such data.
            </p>

        </div>
        <div id="Support">
          <h4><strong>XXII. Support</strong></h4>
          <AudioSection 
              audioUrl={supportUrl}  
              onPlay={handlePlayAudio} 
              isPlaying={audioPlaying === supportUrl}/> 
            <p>
              As a Contranorm Media & Production client, you may interact with our support staff via live chat, or by submitting a support ticket. The level of engagement and assistance varies depending on the support option available.
            </p>
            <div className={`support-table ${collapsed ? 'collapsed' : ''}`}>
              <table>
                <thead>
                  <tr>
                    <th className="support-table-header">Support Options</th>
                    <th className="support-table-header">Free</th>
                    <th className="support-table-header">Premium</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="support-table-cell">
                      <strong>Community</strong><br />
                      Join the community to ask basic troubleshooting questions and to view the latest resources such as tips for resolving common issues and guidance from your peers online.
                    </td>
                    <td className="support-table-cell">
                      <FaCheck color="purple" />
                    </td>
                    <td className="support-table-cell">
                      <FaCheck color="purple" />
                    </td>
                  </tr>
                  <tr>
                    <td className="support-table-cell">
                      <strong><span style={{ cursor: 'pointer', color: 'purple' }} onClick={handleNavigation}>Support Ticket</span></strong><br />
                      Use ticket@contranormdigital.com to troubleshoot a specific issue or error. Response times may vary and may be AI generated.
                    </td>
                    <td className="support-table-cell">
                      <FaTimes color="pink" />
                    </td>
                    <td className="support-table-cell">
                      <FaCheck color="purple" />
                    </td>
                  </tr>
                  <tr>
                    <td className="support-table-cell">
                    <strong><span style={{ cursor: 'pointer', color: 'purple' }} onClick={handleNavigation}>Live Chat</span></strong><br />
                      Subscribe to the Premium Tier to enable the live-chat pop-up in your dashboard to speak with a live agent if your questions, inquiries, or concerns cannot be resolved via the online community or the AI generated response provided by ticket@contranormdigital.com
                    </td>
                    <td className="support-table-cell">
                      <FaTimes color="pink" />
                    </td>
                    <td className="support-table-cell">
                      <FaCheck color="purple" />
                    </td>
                  </tr>
                </tbody>
              </table>
        </div>
        <div id="Termination">
          <h4><strong>XXIII. Termination</strong></h4>
              <p><strong>Contranorm Media & Production reserves the right to deny a request, restrict access, cancel an order, withdraw an offer, and/ or refuse service to any client, at any time, for any reason.</strong></p>
        </div>
        <div id="Copyright">
          <h4><strong>XXIV. Copyright</strong></h4>
          <AudioSection 
              audioUrl={copyrightUrl}  
              onPlay={handlePlayAudio} 
              isPlaying={audioPlaying === copyrightUrl}/> 
            <p></p>
          <p>
            Our website(s), and all of its publicly visible content, including but not limited to articles, other text, photographs, images, illustrations, graphics, video material, audio material, including musical compositions and sound recordings, software, CMP logos, titles, characters, names, graphics, and button icons, collectively "Intellectual Property," are protected by copyright, trademark and other laws of the United States as well as international conventions and laws of other countries.  The  Intellectual  Property is owned, managed, and/or controlled by Contranorm Media & Production and/or other parties that have provided rights to Contranorm Media & Production for publication.
              
            You may not, and agree that you will not reproduce, download, license, publish, enter into a database, display, modify, create derivative works from, transmit, post, distribute, or perform publicly by any means, method, or process now known or later developed, decompile, reverse engineer, disassemble, use on another computer-related environment, transfer or sell any Intellectual Property, information, software or publicly visible digital assets and/or products, obtained, whole or in part, through or from Contranorm Media & Production or its website(s) that have not been sold to you by us. 
              
            You acknowledge and agree that by submitting any data, personally identifiable material, photographs, or digital assets to our website(s), applications, or other online resources, including through email or direct interaction with any user interface or digital platform owned, managed, or operated by Contranorm Media & Production, you represent and warrant that you are the rightful owner of the submitted material and that no other party may claim rights to it prior to its submission. Unless protected by trademark or copyright, such submission grants Contranorm Media & Production and its designees a royalty-free, fully paid, non-exclusive, irrevocable, perpetual, unrestricted, worldwide license to reproduce, publish, transmit, perform, display, sublicense, create derivative works from, and otherwise use the submitted material for any purpose, including but not limited to advertising and promotional purposes, in any form, media, or technology now known or later developed.

            No credit, approval, or compensation is due to you with regard to said data. Any non-personally identifiable material, digital assets, information, or data acquired through your engagement with our online resources will be treated as non-proprietary and non-confidential unless explicitly protected under our Privacy Policy or other applicable legal protections.
              
            Other trademarks, service marks, product names and company names or logos appearing on our website(s) that are not owned by Contranorm Media & Production may not be used without the expressed, written permission from their owner(s).
          </p>
        </div>
        <div id="DCMA">
          <h4><strong>XXV. DCMA</strong></h4>  
          <p>
            This site is owned, operated, and/or managed in the REPUBLIC OF HONDURAS by Contranorm Media & Production, (CMP) or its Authorized Agent. 
            Contranorm Media & Production respects the Intellectual Property Rights of others, and we expect our clients and visitors to our website to do the same. We will respond to clear notices of copyright infringement consistent with the Digital Millennium Copyright Act (“DMCA”) under 17 U.S.C. &#167; 512(c). If you have a question or concern about copyright infringement, please forward your inquiries or requests to ticket@contranormdigital.com.  If you are requesting that material or digital assets be removed, please email a written request and ensure that the single document attached to your email includes:
          </p>
       
            <p  className="centeredItemStyle">
          
            (1) A physical signature of the person authorized to act on behalf of the owner of an exclusive right that has allegedly been infringed.
            </p>
                <br />
            <p  className="centeredItemStyle">
            (2) A full description of the copyrighted work claimed to have been infringed, or if multiple copyrighted works at a single url are covered by a single notification, a representative list of such works present via the url.
            </p>
                <br />
            <p  className="centeredItemStyle">
            (3) A full description of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled and enough information to reasonably permit Contranorm Media & Production the ability to locate the material or digital asset.
            </p>
                <br />
            <p  className="centeredItemStyle">
            (4) Enough information that permits Contranorm Media & Production to contact the complaining party, such as an email address and telephone number, and if available, the physical address of the complaining party.
            </p>
                <br />
            <p  className="centeredItemStyle">
            (5) A statement positively affirming that the complaining party has a good faith belief that the use of the material and/or digital asset is not authorized by the copyright owner, its agent, or the law.
            </p>
                <br />
            <p  className="centeredItemStyle">
            (6) A statement that the information in the notification is complete and accurate, under penalty of perjury, and that the complaining party is authorized to act on behalf of the owner of an exclusive right that has allegedly been infringed. 
            </p>
        
          </div>    
        </div>
      </div>
    </div>
  );
}

export default Policies;
